import { BaseListWebService } from '@web/base/shared/components/base-list-web/base-list-web.service';
import { Component } from '@angular/core';
import { SliderContentComponent as SliderContentComponentBase } from '@web/base/core/home/slider-content/slider-content.component';

@Component({
  selector: 'web-project-home-slider-content',
  templateUrl:
    './../../../shared/components/base-list-web/base-list-web.component.html',
  styleUrls: [
    './../../../shared/components/base-list-web/base-list-web.component.scss',
  ],
  providers: [BaseListWebService],
})
export class SliderContentComponent extends SliderContentComponentBase { }
