import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { SharedService } from '@web/project/shared/shared.service';
import { IResponse } from '@web/project/shared/models/response.model';
import { Subject } from 'rxjs';
import { ITableEstructure } from '@web/project/shared/models/table-structure.model';

@Component({
  selector: 'web-base-search-element',
  templateUrl: './search-element.component.html',
  styleUrls: ['./search-element.component.scss'],
})
export class SearchElementComponent extends BaseComponent implements OnInit {
  public formTemplate: TemplateRef<any>; // Plantilla para el formulario para buscar elementos.
  public formFilter: FormGroup; // Formulario para la búsqueda de elementos.

  public isSaving: boolean;

  public elements$: Subject<Array<any>> = new Subject<Array<any>>();

  public total = 0;
  quantity = 50;

  public totalBadge: String;

  // Datos recibidos por el dialog
  public structure: Array<ITableEstructure> = [];
  public additionalOptions = [{ name: 'selectElement', icon: 'add' }];
  public loadFunction: string;
  public sectionTitle: string;
  public extraFilter = {};
  public canCreate = true;
  public urlCreate: string;

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SearchElementComponent>,
    public sharedService: SharedService
  ) {
    super(translate, snackbar, dialog);
  }

  ngOnInit() {
    if (!this.formFilter) {
      this.formFilter = new FormGroup({
        filter: new FormControl(null, [
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
        page: new FormControl(0),
        quantity: new FormControl(this.quantity),
      });

      this.formFilter.controls['filter'].valueChanges
        .pipe(debounceTime(800))
        .subscribe((data) => {
          this.formFilter.controls['page'].setValue(0);

          const value = this.formFilter.value;
          value.filter = data;

          this.getData(value);
        });
    }

    Object.keys(this.formFilter.value).forEach((k) => {
      if (k !== 'page' && k !== 'quantity') {
        if (k === 'filter') {
          this.formFilter.controls['filter'].valueChanges
            .pipe(debounceTime(800))
            .subscribe((data) => {
              this.formFilter.controls['page'].setValue(0);

              const value = this.formFilter.value;
              value.filter = data;

              this.getData(value);
            });
        } else {
          this.formFilter.controls[k].valueChanges.subscribe((data) => {
            this.formFilter.controls['page'].setValue(0);

            const value = this.formFilter.value;
            value[k] = data;

            this.getData(value);
          });
        }
      }
    });

    this.getData(this.formFilter.value);
  }

  close(val?) {
    this.dialogRef.close(val);
  }

  getData(filters) {
    Object.keys(this.extraFilter).forEach((k) => {
      filters[k] = this.extraFilter[k];
    });

    this.sharedService[this.loadFunction](filters).subscribe(
      (response: IResponse) => {
        this.total = response.result.total;
        this.totalBadge = this.total > 99 ? '99+' : this.total + '';

        this.elements$.next(response.result.items);
      }
    );
  }

  selectElement(event: { item: any; index: number }) {
    this.dialogRef.close(event.item);
  }

  loadPage(index: number) {
    this.formFilter.controls['page'].setValue(index);
    this.getData(this.formFilter.value);
  }

  // TODO
  openDialog() { }
}
