import { SharedService } from '@web/project/shared/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { Component, OnInit } from '@angular/core';
import { BaseListWebComponent as BaseListWebComponentBase } from '@web/base/shared/components/base-list-web/base-list-web.component';
import { BaseListWebService } from '@web/project/shared/components/base-list-web/base-list-web.service';

@Component({
  templateUrl: './base-list-web.component.html',
  styleUrls: ['./base-list-web.component.scss'],
  providers: [BaseListWebService],
})
export class BaseListWebComponent
  extends BaseListWebComponentBase
  implements OnInit {
  constructor(
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public snackbar?: MatSnackBar,
    public dialog?: MatDialog,
    public meta?: Meta,
    public title?: Title,
    public route?: ActivatedRoute,
    public sharedService?: SharedService,
    public baseListWebService?: BaseListWebService,
    public router?: Router
  ) {
    super(
      translate,
      sanitizer,
      snackbar,
      dialog,
      meta,
      title,
      route,
      sharedService,
      baseListWebService,
      router
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: true,
      navSpeed: 5000,
      center: true,
      autoplay: true,
      items: 1,
      autoWidth: true,
    };
  }
}
