import { CoreService } from '@web/base/core/core.service';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'web-banners-certamenes',
  templateUrl: './banners-certamenes.component.html',
  styleUrls: ['./banners-certamenes.component.scss'],
})
export class BannersCertamenesComponent extends BaseComponent implements OnInit {
  @Input() banners;

  bannerOptions: OwlOptions;

  constructor(translate: TranslateService, public coreService: CoreService) {
    super(translate);
  }

  ngOnInit() {
    this.bannerOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      rtl: true,
      autoplay: true,
      autoWidth: true,
      responsive: {
        0: { items: 1 },
        600: { items: 2 },
        960: { items: 3 }
      },
    };
  }
}
