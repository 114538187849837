import { EmbedVideoService } from './embed-video-service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { VideosModuleComponent as VideosModuleComponentBase } from '@web/base/shared/components/videos-module/videos-module.component';

@Component({
  selector: 'web-videos-module',
  templateUrl:
    './../../../../base/shared/components/videos-module/videos-module.component.html',
  styleUrls: [
    './../../../../base/shared/components/videos-module/videos-module.component.scss',
    './videos-module.component.scss',
  ],
})
export class VideosModuleComponent extends VideosModuleComponentBase {
  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public embedVideoService: EmbedVideoService
  ) {
    super(dialog, sanitizer, embedVideoService);
  }
}
