import { publicS3Url } from './../../../environments/environment';
import { Constants } from '@web/base/shared/constants.class';
import { MenuItem } from '../core/menu/menu-item.model';

export class ConstantsProject extends Constants {
  // IMPORTANTE -- Hay que definir estas variables en cada proyecto para que un proyecto no afecte a SSL_OP_TLS_ROLLBACK_BUG.
  // token de enfermeriagranada
  static TOKEN_KEY = '2341a64b5bbb2de1b7bc5b99e999b38b';
  static COOKIES_PREFIX = '_2341a64b5bbb2de1b7bc5b99e999b38b_';
  static COOKIES_CONSENT = '__CodegraCookiesConsent';

  // static GOOGLE_ANALYTICS4_ID = '1T95FTMBT'; // Clave sin la 'G-' inicial
  static GOOGLE_ANALYTICS4_ID = '1T95FTMBT4'; // Clave sin la 'G-' inicial

  //----------------------------------
  // ACCESO A GOOGLE DRIVE
  // Estas claves son de una cuenta proporcionada por el Colegio:
  //----------------------------------
  static GOOGLE_SERVICE_ACCOUNT =
    'codegra@imagenes-web-del-colegio.iam.gserviceaccount.com';
  static GOOGLE_SERVICE_ACCOUNT_PRIVATE_KEY =
    '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCt4c0ex4o33kzK\nsWXv1Nl0WFsFo6QDnAO54kxvTSblmdV6LnO2ycTO9KOvzD9OOilzfDKWeyWOXdn7\nhtwMEtoUdHiC5wpksifBs132xQ/s/ubiv+GiSBxEMHVisiNPn52Xx2jD81UhgfD6\nYTqIT8KaNM6muMvAVi+cJY+2f/mPdDPNJ9kNw6PDcxj0+tOGUJxvsBAflvr3gB2T\nw9B3SPZmHxeM5TtXm/fCdqmrMsE8SAN2+RYTctLtPzvWUmKNb7OeIqrZ25w0qasB\nCXEoTqe/g8GEJssIg0LgQE11Naqe5wsP8414C9in9O+pRV8BEeS9Iz/9UnrYzHmh\nm+1M2aabAgMBAAECggEAGDg58n4XvgVsS9Ve3gOXUHHYJ9Zty4TpLfSb8ercrqF5\nTz32JzE+hRclT6DS9on6aRgVEcnQZt6itLrJnrLnhm7ctFsLIxB/oibiQTOcrsWo\nkk/o1UpXCZZ4W0l1yqrwcAo6CQU1R+gKEU4GsaLud71ARY+67azzj7vZozF7KORr\nYBFMk0U/AekX2NspIGhcea9zqkrpLWVVDgrFHj84en9JDsnSBvJ8uTFOD5pEotve\nuzYdH7Bu5tL+EJm9cnIzhtNzxatKZSnLCVUNU97ANh8H3EOYBdGE3phGCAI3TAFY\n4Lxi9St8bKY6YuhREGXgfYvoKKt77wovBwfvEdxKYQKBgQDZFUJKUH8yNUJCUqqu\nZV8T8dO2bL6yO+qFj6B4MxBuR8W9C1fCHqjPbV9Wvj1KeTgRAPut49tqrLDkGMoD\npjZTbxSWt6W7RJJ5/tDjRbM8/fWighmw2au7bkiXgDCQQK90mEhCnjkmCZnbB3Hy\nEEX2wpxyuC9z05cQA2dmh1Cx+wKBgQDNDeOMZUxYHXcGgbKYF+K3FeO3VK9TnofM\nKuZbyRkWiLoIqFAatOBD3djZRe7v3dgb4TFLBo+4epwzGSTef5VOCd6DYYSjd3Qx\notrMbPI2BdmBBxYcQrzsjgWMBfrJypNEWijMTPIFMMFiXcyjwrxldl82QDJy4IYU\nUOci16tb4QKBgQCJxzdiqUKBoFHkfEk+FLLKmtKPVueVgfewO19Syr4j8EOtX1Sc\nrQ7hT/6RtH58FQGjsScLz7GEPAStUJpuxIB4ZekAW6DBgZ0h9runc6Z2EI1lTgN2\nPkED3dQO69pmw9dYPgqm1XtaC//thwUn6HIvUocvPH1Jm0KmdDKdXUa3HwKBgHPg\nVGWjXPgydm+z3WP7o3/JG+0fKxD69kyXNaRKySWl0FNSIiplapKPjJqD3ZmpaRWg\nNYXrSE5840YxAFfIC2yVQSxHcHgb6gqeuDxrmJiUx6wVgBYqVK7tNw9A1c8w7fXj\naUjCdsYeHjpjPWCRjHtd4EI9FwU6Air66RMUg16hAoGABef8tvNSSpl5pUSgcsRr\nRyUWSNMcufGCTqakh56Mrl9vIf66sPN9p6J7rMyqs8r+nq0svipQuYLAaLw80qKF\nWkLUADRAJ5FTYM+CdOQCtYIs2NYdxhZQpeuHF+TI6roUAN6vuccgMvW+nJ34P8u2\ncUjFjrJXGdoS/+YrdMuBZXM=\n-----END PRIVATE KEY-----\n';
  //----------------------------------

  static PROJECT_LOCATION_MAIN = { lat: 37.170353, lng: -3.599766 };

  static YOUTUBE_CHANNEL_ID = 'UCt-fKbRyHylf3vR_vet4n9Q';

  static TWITTER = '@codegraes';

  static HEADER_HEIGHT = 114;

  static OFFICE_ADDRESSES = [
    {
      id: 1,
      office: 'Ilustre Colegio Oficial de Enfermería de Granada',
      info: {
        address: 'C/ San Antón, 36, 18005 Granada',
        phone: 'Tfno: 958 53 52 29',
        email: 'info@codegra.es',
      },
    },
  ];

  static MENU_SIDENAV: Array<MenuItem> = [];

  static MENU_HEADER: Array<MenuItem> = [
    {
      title: 'college',
      children: [
        { title: 'welcome', url: '/p/1/bienvenida' },
        { title: 'board', url: '/colegio/junta-de-gobierno' },
        { title: 'legislation', url: 'p/2/legislacion-colegial' },
        { title: 'horario', url: 'p/3/sede-colegial-y-horarios' },
        {
          title: 'comisionDeontologica',
          children: [
            { title: 'theCommission', url: '/colegio/comision-deontologica' },
            { title: 'members', url: 'colegio/comision-deontologica-miembros' },
            {
              title: 'bioethicNews',
              children: [
                { title: 'forum', url: 'contenidos/5/cine-forum' },
                { title: 'deontologicalCodeProject', url: 'p/1418/proyecto-codigo-deontologico' }
              ],
            },
          ],
        },
        { title: 'retiredAssociation', url: 'p/1438/asociacion-jubilados-codegra' },
        {
          title: 'ongSEG',
          url: 'https://www.solidaridadenfermera.org/granada/',
          external: true,
        },
        // { title: 'transparency', url: '/transparencia' },
        { title: 'contact', url: '/contacto', separator: true },
      ],
    },
    {
      title: 'services',
      children: [
        { title: 'polizaRC', url: 'p/5/poliza-rc' },
        { title: 'asesoriaJuridica', url: 'p/7/asesoria-juridico-laboral' },
        { title: 'asesoriaFiscal', url: 'p/8/asesoria-fiscal' },
        { title: 'asesoriaInvestigacion', url: 'p/9/asesoria-investigacion' },
        {
          title: 'asesoriaProfesional',
          url: 'p/10/asesoria-carrera-profesional',
        },
        { title: 'prestaciones', url: 'p/11/prestaciones-sociales' },
        { title: 'ayudasCongresos', url: 'p/12/ayudas-congresos' },
        { title: 'advertisements', url: 'tablon-de-anuncios' },
        { title: 'agreements', url: '/servicios/convenios' },
      ],
    },
    {
      title: 'formacion',
      children: [
        { title: 'presentation', url: 'p/13/formacion-presentacion' },
        {
          title: 'bases',
          children: [
            { title: 'presencial', url: 'p/14/formacion-bases-presenciales' },
            { title: 'online', url: 'p/15/formacion-bases-online' },
          ],
        },
        { title: 'inscripcionCursos', url: 'p/16/formacion-inscripcion' },
        { title: 'formationCertificates', url: 'cursos/diplomas-disponibles' },
        { title: 'cursosOnline', url: 'cursos/buscar' },
        // { title: 'jornadasCodegra', url: 'cursos/jornadas' },
        // { title: 'jornadas', url: 'contenidos/7/jornadas-y-congresos', private: true },
      ],
    },
    {
      title: 'employment',
      children: [
        { title: 'deInteres', url: 'p/17/empleo-de-interes' },
        { title: 'bolsaEmpleo', url: 'p/18/bolsa-empleo' },
        { title: 'nacional', url: 'empleo-nacional' },
        { title: 'extranjero', url: 'p/19/empleo-extranjero' },
      ],
    },
    {
      title: 'publications',
      children: [
        {
          title: 'coronavirus',
          // url: 'contenidos/14/coronavirus',
          children: [
            {
              title: 'medidasPreventivas',
              url: 'p/21/medidas-preventivas-covid19',
            },
            {
              title: 'desinfeccion',
              url: 'p/22/desinfeccion-domicilios-afectados-covid19',
            },
          ],
        },
        {
          title: 'enfermeriaFacultativa',
          children: [
            {
              title: 'edicionNacional',
              url: 'http://www.consejogeneralenfermeria.org/index.php/sala-de-prensa/revista-colegial',
              external: true,
              private: true,
            },
            {
              title: 'edicionAndalucia',
              url: 'http://www.andalucia.consejogeneralenfermeria.org/',
              external: true,
              private: true,
            },
          ],
        },
        {
          title: 'nursingNowGranada',
          // url: 'publicaciones/nursing-now-granada',
          children: [
            {
              title: 'whatIsNursingNow',
              url: 'publicaciones/nursing-now-granada',
            },
            { title: 'campaign', url: 'p/23/nursing-now-granada-campanya' },
            { title: 'actas', url: 'contenidos/9/nursing-now-granada-actas' },
            {
              title: 'agenda',
              url: 'contenidos/10/nursing-now-granada-agenda',
            },
          ],
        },
        {
          title: 'divulgacion',
          children: [
            {
              title: 'herramientas',
              url: 'contenidos/13/herramientas-autocuidado-post-covid19',
            },
          ],
        },
        {
          title: 'garnata91',
          // url: 'publicaciones/revista-garnata-91',
          children: [
            { title: 'garnata91Info', url: 'publicaciones/revista-garnata-91' },
            { title: 'garnata91-0-22', url: 'publicaciones/revista' },
            {
              title: 'garnata91-23',
              url: 'http://ciberindex.com/index.php/g91',
              external: true,
            },
          ],
        },
        { title: 'newsletters', url: '/publicaciones/boletin-digital' },
        { title: 'circulars', url: 'contenidos/3/circulares', private: true },
        { title: 'boe', url: 'https://www.boe.es/diario_boe/', external: true },
        {
          title: 'boja',
          url: 'http://www.juntadeandalucia.es/eboja.html',
          external: true,
        },
        { title: 'bop', url: 'https://bop.dipgra.es/bop.asp', external: true },
        { title: 'news', url: 'contenidos/1/noticias' },
        {
          title: 'prescripcion',
          url: 'contenidos/8/prescripcion',
          private: true,
        },
        { title: 'polls', url: '/encuestas' },
        { title: 'links', url: '/publicaciones/enlaces-de-interes' },
        {
          title: 'imagenes',
          url: '/publicaciones/galeria-imagenes',
          separator: true,
        },
        { title: 'videos', url: '/publicaciones/galeria-videos' },
      ],
    },
    {
      title: 'events',
      children: [
        { title: 'events', url: 'contenidos/11/eventos' },
        { title: 'socioCultural', url: 'contenidos/12/socio-cultural' },
      ],
    },
    {
      title: 'actividadCientifica',
      children: [
        {
          title: 'diaEnfermeria',
          url: 'https://diegranada.com',
          external: true,
        },
        { title: 'premioJuanCiuidad', url: 'p/27/premio-juan-ciudad' },
        {
          title: 'VIIIJornadasProyeccionEnfermera',
          url: 'https://proyeccionenfermera.codegra.es/index',
          external: true,
        },
        {
          title: 'ciberindex',
          url: 'p/1040/acceso-a-ciberindex',
          external: true,
        },
        { title: 'jornadasCodegra', url: 'cursos/jornadas' },
        {
          title: 'jornadas',
          url: 'contenidos/7/jornadas-y-congresos',
          private: false,
        },
      ],
    },
    {
      title: 'virtualOffice',
      url: '/ventanilla-unica',
      children: [
        { title: 'queEs', url: 'p/35/que-es' },
        { title: 'deontologicalCode', url: 'p/36/codigo-deontologico' },
        // { title: 'colegiacion', url: 'p/4/como-colegiarse' },
        { title: 'colegiacion', url: '/ventanilla-unica/como-colegiarse' },
        {
          title: 'solicitarColegiacion',
          url: '/ventanilla-unica/alta-colegial',
        },
        {
          title: 'censoColegial',
          url: '/ventanilla-unica/listado-de-profesionales',
        },
        { title: 'complaints', url: '/quejas-reclamaciones-sugerencias' },
        {
          title: 'aulaCodegra',
          url: 'http://www.codegra-formacion.es/',
          external: true,
        },
        {
          title: 'memories',
          url: '/contenidos/4/memorias',
          private: true,
          separator: true,
        },
        {
          title: 'certificatesDownload',
          url: '/ventanilla-unica/descarga-de-certificados',
          private: true,
        },
        {
          title: 'dismissals',
          url: '/ventanilla-unica/solicitud-baja-colegial',
          private: true,
        },
        {
          title: 'solicitudHabilitacionTemporal',
          url: '/ventanilla-unica/solicitud-habilitacion-temporal',
          private: true,
        },
      ],
    },
  ];

  // Tipos de empresa del colegiado
  static COLLEGIATE_COMPANY_TYPES = [
    {
      value: 1,
      text: 'private',
      textReadable: '',
    },
    {
      value: 2,
      text: 'public',
      textReadable: '',
    },
  ];

  static PERIODS_RETENTION_ANUAL = 3;
  static PERIODS_RETENTION = [
    {
      value: 1,
      text: 'semesterFirst',
      textReadable: '',
    },
    {
      value: 2,
      text: 'semesterSecond',
      textReadable: '',
    },
    {
      value: 3,
      text: 'anual',
      textReadable: '',
    },
  ];

  static PROFESSIONAL_EXERCISE_PUBLIC = 3;
  // static PROFESSIONAL_EXERCISES = Constants.PROFESSIONAL_EXERCISES.map(e => {
  //   e.text = (e.value === ConstantsProject.PROFESSIONAL_EXERCISE_PUBLIC) ? 'public' : e.text;
  //   return e;
  // });

  static PROFESSIONAL_EXERCISES = [
    {
      value: 1,
      text: 'andalusianHealthService',
      textReadable: '',
    },
    {
      value: 2,
      text: 'notSASSButBelongingToTheMinistryOfHealth',
      textReadable: '',
    },
    {
      value: 3,
      text: 'publicNotAndalusia',
      textReadable: '',
    },
    {
      value: 4,
      text: 'otherPublicAdministration',
      textReadable: '',
    },
    {
      value: 5,
      text: 'private',
      textReadable: '',
    },
    {
      value: 6,
      text: 'privateArranged',
      textReadable: '',
    },
  ];

  static PROFESSIONAL_CATEGORY_GROUPS = [
    {
      value: 1,
      text: 'a1',
      textReadable: '',
    },
    {
      value: 2,
      text: 'a2',
      textReadable: '',
    },
    {
      value: 3,
      text: 'b',
      textReadable: '',
    },
    {
      value: 4,
      text: 'c1',
      textReadable: '',
    },
    {
      value: 5,
      text: 'c2',
      textReadable: '',
    },
  ];

  static PROFESSIONAL_DEGREES = [
    {
      value: 1,
      text: 'level1',
      textReadable: '',
    },
    {
      value: 2,
      text: 'level2',
      textReadable: '',
    },
    {
      value: 3,
      text: 'level3',
      textReadable: '',
    },
    {
      value: 4,
      text: 'level4',
      textReadable: '',
    },
    {
      value: 5,
      text: 'level5',
      textReadable: '',
    },
  ];

  static PROFESSIONAL_LEVELS = [
    {
      value: 1,
      text: 'expert',
      textReadable: '',
    },
    {
      value: 2,
      text: 'advanced',
      textReadable: '',
    },
    {
      value: 3,
      text: 'excellent',
      textReadable: '',
    },
  ];

  static PROFESSIONAL_DEVELOPMENT_LEVELS = [
    {
      value: 1,
      text: 'entry',
      textReadable: '',
    },
    {
      value: 2,
      text: 'first',
      textReadable: '',
    },
    {
      value: 3,
      text: 'second',
      textReadable: '',
    },
    {
      value: 4,
      text: 'third',
      textReadable: '',
    },
    {
      value: 5,
      text: 'forth',
      textReadable: '',
    },
  ];

  static PROFESSIONAL_FUNCTIONS = [
    {
      value: 1,
      text: 'assistance',
      textReadable: '',
    },
    {
      value: 2,
      text: 'no-assistance',
      textReadable: '',
    },
    {
      value: 3,
      text: 'no-activity',
      textReadable: '',
    },
  ];

  static BANNER_TOP = 1;
  static BANNER_CONVENIOS = 2;
  static BANNER_CERTAMENES = 3;

  static CONTACT_TYPES = {
    GENERAL: 1,
    COMPLAINT: 2,
    CLAIM: 3,
    SUGGESTION: 4,
    INTRUSION_OBSERVATORY: 5,
  };

  // Biblioteca
  static LIBRARY_ARTICLE_COPY_STATUS = {
    DAMAGED: 1,
    LOST: 2,
    BORROWED: 3,
    AVAILABLE: 4,
    AVAILABLE_ONSITE: 5,
    DOWNLOADABLE: 6,
    QUARANTINE: 7,
  };

  // Tipos de baja colegiales
  static MOVEMENT_TYPE_CATEGORY_DISMISSAL_RETIREMENT = 7; // Motivo de baja por jubilación

  static TYPES_REPORTS = {
    WELCOME: 1,
    REGISTRATION: 2,
    BE_REGISTRED: 3,
    BE_PAYMENT: 4,
    CERT_RETENTION: 5,
    LABELS: 6,
    CERT_RC: 100,
    CERT_ABROAD_TRANSLATION: 109,
  };

  // Formularios

  static WEB_FORM_QUESTION_TYPE_TEXT = 1;
  static WEB_FORM_QUESTION_TYPE_LONG_TEXT = 2;
  static WEB_FORM_QUESTION_TYPE_SELECT = 3;
  static WEB_FORM_QUESTION_TYPE_NUMERIC_RADIO = 4;
  static WEB_FORM_QUESTION_TYPE_MULTIPLE = 5;
  static WEB_FORM_QUESTION_TYPE_DATE = 6;
  static WEB_FORM_QUESTION_TYPE_FILE = 7;

  static FORMATION_TYPE_WORKING_DAY = 5;

  // Añadimos la modalidad mixta
  static FORMATION_MODALITY_MIXED = 5;
  static FORMATION_MODALITIES = Constants.FORMATION_MODALITIES.concat([
    {
      value: 5,
      text: 'mixed',
      textReadable: '',
    },
  ]);

  static FORMATION_MODALITY_MIXED_TYPE_ONSITE = 1;
  static FORMATION_MODALITY_MIXED_TYPE_ONLINE = 2;
  static FORMATION_MODALITY_MIXED_TYPES = [
    {
      value: 1,
      text: 'onSite',
      textReadable: '',
    },
    {
      value: 2,
      text: 'online',
      textReadable: '',
    },
  ];

  static ELEMENT_TYPE_RETIRED = 101;
  static ELEMENT_TYPE_VOLUNTARY = 102;

  static USERS_TYPES = Constants.USERS_TYPES.filter(
    (e) => e.value !== Constants.ELEMENT_TYPE_PRECOLLEGIATE
  ).concat([
    {
      name: 'retired',
      value: ConstantsProject.ELEMENT_TYPE_RETIRED,
      text: '',
    },
    {
      name: 'voluntary',
      value: ConstantsProject.ELEMENT_TYPE_VOLUNTARY,
      text: '',
    },
  ]);

  publicS3Url = publicS3Url;
  constructor() {
    super();
  }
}
