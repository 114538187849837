import { Component } from '@angular/core';
import { NewslettersSubscriptionComponent as NewslettersSubscriptionComponentBase } from '@web/base/core/newsletters-subscription/newsletters-subscription.component';

@Component({
  selector: 'web-project-newsletters-subscription',
  // templateUrl: './../../../base/core/newsletters-subscription/newsletters-subscription.component.html',
  templateUrl: './newsletters-subscription.component.html',
  styleUrls: ['./newsletters-subscription.component.scss'],
})
export class NewslettersSubscriptionComponent extends NewslettersSubscriptionComponentBase { }
