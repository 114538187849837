import { getCookie, setCookie, deleteCookie } from '@web/project/shared/utils';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { MatCheckbox } from '@angular/material/checkbox';
import { CookiesService } from '../cookies.service';
import { BaseComponent } from '@web/project/shared/components/base/base.component';

@Component({
  selector: 'web-project-cookies-consent',
  templateUrl: './cookies-consent.component.html',
  styleUrls: ['./cookies-consent.component.scss'],
})
export class CookiesConsentComponent extends BaseComponent implements OnInit {
  COOKIES_CONSENT = ConstantsProject.COOKIES_CONSENT;
  COOKIES_PREFIX = ConstantsProject.COOKIES_PREFIX;
  GOOGLE_ANALYTICS4_ID = ConstantsProject.GOOGLE_ANALYTICS4_ID;

  @Input('showCookiesInfo') showCookiesInfo: Boolean;

  @Output() openCookiesPolicy = new EventEmitter();
  @Output() acceptCookies = new EventEmitter();

  @ViewChild('functionalCheck', { static: false }) functionalCheck: MatCheckbox;
  @ViewChild('statsCheck', { static: false }) statsCheck: MatCheckbox;
  @ViewChild('detailsTable', { static: false }) detailsTable: ElementRef;

  cookie = null;
  statsCookies = false;
  functionalCookies = false;

  detailShowed = false;

  constructor(
    public translate: TranslateService,
    public renderer: Renderer2,
    public router: Router,
    public cookiesService: CookiesService
  ) {
    super(translate);
  }

  ngOnInit() {
    this.cookie = JSON.parse(getCookie(this.COOKIES_CONSENT));
    if (this.cookie) {
      this.statsCookies = this.cookie.stats;
      this.functionalCookies = this.cookie.functionals;
      if (this.statsCookies) {
        this.activateStatsCookies();
      }
      if (this.functionalCookies) {
        this.activatefunctionalCookies();
      }
      this.cookiesService.setCookiesConsent.next(true);
    }
  }

  // showInfo() {
  //   this.openCookiesPolicy.emit();
  // }

  accept() {
    this.acceptCookies.emit();
    this.cookiesService.setCookiesConsent.next(true);
    this.router.navigateByUrl('/home'); // Redirigimos a la home tras la selección
    window.location.reload();
  }

  necessary() {
    this.functionalCheck.checked = false;
    this.statsCheck.checked = false;
    this.functionalCookies = false;
    this.statsCookies = false;
    setCookie(
      this.COOKIES_CONSENT,
      JSON.stringify({ stats: false, functionals: false }),
      99999
    );

    this.disableStatsCookies();
    this.accept();
  }

  selected() {
    setCookie(
      this.COOKIES_CONSENT,
      JSON.stringify({
        stats: this.statsCheck.checked,
        functionals: this.functionalCheck.checked,
      }),
      99999
    );
    this.statsCookies = this.statsCheck.checked;
    this.functionalCookies = this.functionalCheck.checked;

    // Stats
    if (this.statsCookies) {
      this.activateStatsCookies();
    } else {
      this.disableStatsCookies();
    }

    // functionals
    if (this.functionalCookies) {
      this.activatefunctionalCookies();
    }

    this.accept();
  }

  all() {
    this.functionalCheck.checked = true;
    this.statsCheck.checked = true;
    this.functionalCookies = true;
    this.statsCookies = true;
    setCookie(
      this.COOKIES_CONSENT,
      JSON.stringify({ stats: true, functionals: true }),
      99999
    );

    this.activateStatsCookies();
    this.activatefunctionalCookies();

    this.accept();
  }

  activateStatsCookies() {
    // Scripts (Google Analytics)
    var scripts = document.querySelectorAll(
      '[data-cookiescript="accepted"][data-cookiecategory="stats"]'
    );
    scripts.forEach((s) => {
      if (s.tagName && s.tagName.toUpperCase() === 'SCRIPT') {
        this.renderer.setAttribute(s, 'type', 'text/javascript');
        if (s.getAttribute('data-src')) {
          this.renderer.setAttribute(s, 'src', s.getAttribute('data-src'));
          this.renderer.setAttribute(s, 'data-src', null);
        }
        this.renderer.setAttribute(s, 'data-cookiescript', null);
        this.renderer.setAttribute(s, 'data-cookiecategory', null);
        eval(s.innerHTML);
      }
    });
  }

  activatefunctionalCookies() {
    var items = document.querySelectorAll(
      '[data-cookiescript="accepted"][data-cookiecategory="functional"]'
    );
    items.forEach((s) => {
      // Scripts
      if (s.tagName && s.tagName.toUpperCase() === 'SCRIPT') {
        this.renderer.setAttribute(s, 'type', 'text/javascript');
        if (s.getAttribute('data-src')) {
          this.renderer.setAttribute(s, 'src', s.getAttribute('data-src'));
          this.renderer.setAttribute(s, 'data-src', null);
        }
        this.renderer.setAttribute(s, 'data-cookiescript', null);
        this.renderer.setAttribute(s, 'data-cookiecategory', null);
        eval(s.innerHTML);
      }

      // Iframes
      if (s.tagName && s.tagName.toUpperCase() === 'IFRAME') {
        if (s.getAttribute('data-src')) {
          this.renderer.setAttribute(s, 'src', s.getAttribute('data-src'));
          this.renderer.setAttribute(s, 'data-src', null);
        }
        this.renderer.setAttribute(s, 'data-cookiescript', null);
        this.renderer.setAttribute(s, 'data-cookiecategory', null);
      }
    });
  }

  disableStatsCookies() {
    deleteCookie('_ga');
    deleteCookie('_gat');
    deleteCookie('_gid');
    deleteCookie('_utma');
    deleteCookie('_utmb');
    deleteCookie('_utmc');
    deleteCookie('_utmz');
    deleteCookie('_ga_' + this.GOOGLE_ANALYTICS4_ID);
  }

  toggleDetails() {
    // console.log(this.detailsTable);
    if (this.detailShowed) {
      this.renderer.setStyle(
        this.detailsTable.nativeElement,
        'display',
        'none'
      );
      this.detailShowed = false;
    } else {
      this.renderer.setStyle(
        this.detailsTable.nativeElement,
        'display',
        'block'
      );
      this.detailShowed = true;
    }
  }
}
