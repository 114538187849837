import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '@web/project/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Component } from '@angular/core';
import { IBreadCrumb } from '../../models/breadcrumb.model';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  template: '',
})
export class BaseWebComponent extends BaseComponent implements OnInit {
  public section: string;
  public sectionTitle: string;
  public showSidenav = true;

  // Marcos - 16/11/2023 - Nueva variable para controlar que no se asignan metas genéricas después de haber asignado metas específicas para esta sección.
  // Puede ocurrir porque cargue antes la llamada a la API al mostrar una noticia que la llamada para obtener las traducciones del proyecto.
  public metasAssigned = false;

  public breadcrumbs: Array<IBreadCrumb> = [];

  constructor(
    public translate: TranslateService,
    public snackbar?: MatSnackBar,
    public dialog?: MatDialog,
    public meta?: Meta,
    public title?: Title,
    public route?: ActivatedRoute,
    public sharedService?: SharedService
  ) {
    super(translate, snackbar, dialog);
  }

  ngOnInit() {
    this.metasAssigned = false;

    this.sharedService.showSidenav.next(this.showSidenav);

    this.setBreadcrumbs();

    if (this.section && this.meta && this.title) {
      const sectionCamelCase = this.section
        .split('.', 1)[0]
        .replace(/-([a-z0-9])/g, function (g) {
          return g[1].toUpperCase();
        });
      this.getTranslations([sectionCamelCase])
        .then(() => {
          if (
            this.translations[sectionCamelCase] &&
            this.translations[sectionCamelCase]['metas']
          ) {
            this.setMetas(
              this.translations[sectionCamelCase]['metas']['title'],
              this.translations[sectionCamelCase]['metas']['description'],
              this.translations[sectionCamelCase]['metas']['keywords']
            );
          } else {
            this.setMetas(
              this.translations['general']['projectName'],
              this.translations['general']['projectName'],
              this.translations['general']['keywords']
            );
          }
          if (
            this.translations[sectionCamelCase] &&
            this.translations[sectionCamelCase]['title']
          ) {
            this.sectionTitle = this.translations[sectionCamelCase]['title'];
          }
        })
        .catch((err) => {
          console.log('err get translations', err);
        });
    } else if (this.meta && this.title) {
      this.getTranslations(['general']).then(() => {
        this.setMetas(
          this.translations['general']['projectName'],
          this.translations['general']['projectName'],
          this.translations['general']['keywords']
        );
      });
    }
  }

  setMetas(title, description?, keywords?, image?) {
    setTimeout(() => {
      if (!this.metasAssigned && this.translations['general']) {
        title = this.translations['general']['projectName'] + ' :: ' + title;
        description = description || title;
        keywords = keywords || this.translations['general']['keywords'];

        // Metas OG ---------
        // this.meta.updateTag({ property: 'fb:app_id', content: this.FACEBOOK_APP_ID });
        this.meta.updateTag({ property: 'og:image', content: image });
        this.meta.updateTag({
          property: 'og:image:secure_url',
          content: image,
        });
        this.meta.updateTag({ property: 'og:image:alt', content: title });
        // this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:type', content: 'article' });
        // this.meta.updateTag({ property: 'og:image:width', content: '300'});
        // this.meta.updateTag({ property: 'og:image:height', content: '200' });
        this.meta.updateTag({
          property: 'og:site_name',
          content: this.translations['general']['projectName'],
        });
        this.meta.updateTag({
          property: 'og:url',
          content: window.location.href,
        });
        this.meta.updateTag({ property: 'og:title', content: title });
        this.meta.updateTag({
          property: 'og:description',
          content: description,
        });

        // Metas generales ---------
        this.title.setTitle(title);
        this.meta.updateTag({ name: 'title', content: title });
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ name: 'keywords', content: keywords });
        this.meta.updateTag({ name: 'image', content: image });

        // Metas de twitter ---------
        this.meta.updateTag({
          name: 'twitter:card',
          content: 'summary_large_image',
        });

        if (ConstantsProject.TWITTER) {
          this.meta.updateTag({
            name: 'twitter:site',
            content: ConstantsProject.TWITTER,
          });
          this.meta.updateTag({
            name: 'twitter:creator',
            content: ConstantsProject.TWITTER,
          });
        }

        this.meta.updateTag({ name: 'twitter:title', content: title });
        this.meta.updateTag({
          name: 'twitter:description',
          content: description,
        });
        this.meta.updateTag({
          name: 'twitter:text:summary_photo_image_alt_text',
          content: title,
        });
        this.meta.updateTag({ name: 'twitter:image', content: image });
        this.meta.updateTag({ name: 'twitter:image:src', content: image });
      }
    });
  }

  // setSection(title) {
  //     if (this.route && this.route.routeConfig && this.route.routeConfig.data) {
  //         this.route.routeConfig.data['section'] = title;
  //         this.sharedService.reloadBreadcrumbs.next(this.route.root);
  //     }
  // }

  setBreadcrumbs() {
    this.sharedService.reloadBreadcrumbs.next(this.breadcrumbs);
  }
}
