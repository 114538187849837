import { NgModule, PLATFORM_ID } from "@angular/core";

import { AppComponent } from "./app.component";

import { StoreModule } from "@ngrx/store";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";

import { CoreModule } from "./project/core/core.module";

import { environment } from "../environments/environment";
import { reducers } from "./base/store/app.reducers";
// import { effects } from './base/store/app.effects';
import { effects } from "./project/store/app.effects";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { registerLocaleData } from "@angular/common";

import { TranslationsLoaderFactory } from "./translations-loader";

import es from "@angular/common/locales/es";

registerLocaleData(es);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationsLoaderFactory,
        deps: [HttpClient, PLATFORM_ID],
      },
    }),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    BrowserAnimationsModule,
  ],
})
export class AppModule { }
