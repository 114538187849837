import { Component } from '@angular/core';
import { PaginateComponent as PaginateComponentBase } from '@web/base/shared/components/paginate/paginate.component';

@Component({
  selector: 'web-paginate',
  templateUrl:
    './../../../../base/shared/components/paginate/paginate.component.html',
  styleUrls: [
    './../../../../base/shared/components/paginate/paginate.component.scss',
  ],
})
export class PaginateComponent extends PaginateComponentBase { }
