import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  HostBinding,
} from '@angular/core';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-base-form-translations',
  templateUrl: './form-translations.component.html',
  styleUrls: ['./form-translations.component.scss'],
})
export class FormTranslationsComponent extends BaseComponent implements OnInit {
  @Input() formTranslations: FormArray; // FormArray que contiene los campos en los distintos idiomas.
  @Input() formTemplate: TemplateRef<any>; // Plantilla HTML para representar el formulario.

  form: FormGroup; // Formulario que contiene el select de idiomas disponibles, y el formArray con los campos de idiomas.

  languages = ConstantsProject.LANGUAGES.sort((a, b) => (a.default ? -1 : 1));
  languageActive = this.languages[0];

  constructor(translate: TranslateService, snackbar: MatSnackBar) {
    super(translate, snackbar);
  }

  ngOnInit() {
    this.getTranslations(['general']).then(() => {
      this.languages.map((l) => {
        l.textReadable = this.translations['general']['languages'][l.text];
        return l;
      });
    });

    // Reordenamos el array de las traducciones, para tener el mismo orden que los idiomas.
    const newFormTranslations = this.formTranslations.controls['translations'];
    const values = newFormTranslations.value;
    const newArray = [];

    this.languages.forEach((l) => {
      newArray.push(values.find((t) => t.languageID === l.value));
    });
    newFormTranslations.patchValue(newArray);

    // Creamos el formulario que gestionará las traducciones, el idioma actual seleccionado y el check de activo
    this.form = new FormGroup({
      languageID: new FormControl(this.languages.find((l) => l.default).value, [
        Validators.required,
      ]),
      active: new FormControl(false),
      // translations: this.formTranslations.controls['translations']
      translations: newFormTranslations,
    });

    // Al marcar/desmarcar la casilla de activo, activamos/desactivamos el idioma actual.
    this.form.controls['active'].valueChanges.subscribe((v) => {
      const indexCurrentLanguage = this.languages.findIndex(
        (l) => l.value === this.form.controls['languageID'].value
      );

      (<FormGroup>(
        (<FormArray>this.form.controls['translations']).controls[
        indexCurrentLanguage
        ]
      )).controls['active'].setValue(v);

      if (v) {
        (<FormGroup>(
          (<FormArray>this.form.controls['translations']).controls[
          indexCurrentLanguage
          ]
        )).enable();
      } else {
        (<FormGroup>(
          (<FormArray>this.form.controls['translations']).controls[
          indexCurrentLanguage
          ]
        )).disable();
      }
    });

    // Al cambiar de idioma, actualizamos el valor de la casilla 'activo' para el nuevo idioma.
    this.form.controls['languageID'].valueChanges.subscribe((v) => {
      const indexCurrentLanguage = this.languages.findIndex(
        (l) => l.value === v
      );
      const active = (<FormGroup>(
        (<FormArray>this.form.controls['translations']).controls[
        indexCurrentLanguage
        ]
      )).controls['active'].value;
      this.form.controls['active'].setValue(active);

      this.languageActive = this.languages[indexCurrentLanguage];
    });

    // Sincronizamos los datos de los dos formularios, para que el componente que utilice éste se entere de los cambios.
    this.form.valueChanges.subscribe((v) => {
      if (v.translations) {
        this.formTranslations.patchValue(v.translations, { emitEvent: false });
      }
    });

    // Si marcamos el campo 'active' de un idioma, habilitamos todos sus campos.
    // En caso contrario, los deshabilitamos.

    (<FormArray>this.form.controls['translations']).controls.forEach(
      (fg: FormGroup, index: number) => {
        if (
          fg.controls['languageID'].value ===
          this.languages.find((l) => l.default).value
        ) {
          this.form.controls['active'].setValue(true);
        }

        // Para el caso inicial
        if (
          fg.controls['active'].value ||
          fg.controls['languageID'].value ===
          this.languages.find((l) => l.default).value
        ) {
          fg.enable();
        } else {
          fg.disable();
        }
      }
    );
  }

  // Al pulsar el botón de copiar a otros idiomas, se volcarán los valores del idioma actual al resto.
  propagateTranslations() {
    const currentLanguage = this.form.value.languageID;
    const currentValues = Object.assign(
      {},
      this.form.value.translations.find((l) => l.languageID === currentLanguage)
    );
    delete currentValues.active;
    delete currentValues.languageID;

    (<FormArray>this.form.controls['translations']).controls.forEach(
      (fg: FormGroup) => {
        if (fg.controls['languageID'] !== currentLanguage) {
          fg.patchValue(currentValues);
        }
      }
    );

    this.openSnackbar(this.translations['general']['translationsPropagated']);
  }
}
