import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { SectionTitleComponent as SectionTitleComponentBase } from '@web/base/shared/components/section-title/section-title.component';

@Component({
  selector: 'web-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent extends SectionTitleComponentBase {
  constructor(
    translate: TranslateService,
    public router: Router,
    public location: Location
  ) {
    super(translate, router, location);
  }
}
