import { Component } from '@angular/core';
import { NoResultsComponent as NoResultsComponentBase } from '@web/base/shared/components/no-results/no-results.component';

@Component({
  selector: 'web-no-results',
  templateUrl:
    './../../../../base/shared/components/no-results/no-results.component.html',
  styleUrls: [
    './../../../../base/shared/components/no-results/no-results.component.scss',
  ],
})
export class NoResultsComponent extends NoResultsComponentBase {
  constructor() {
    super();
  }
}
