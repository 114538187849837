import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Component, Renderer2 } from '@angular/core';
import { CalendarComponent as CalendarComponentBase } from '@web/base/shared/components/calendar/calendar.component';
import { trigger, style, transition, animate } from '@angular/animations';

import * as moment from 'moment';

@Component({
  selector: 'web-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [
    trigger('eventsDayWindow', [
      transition('void => *', [
        style({ opacity: 0, left: '-100%' }),
        animate('100ms', style({ opacity: 1, left: '0px' })),
      ]),
      transition('* => void', [
        style({ opacity: 1, left: 0 }),
        animate('100ms', style({ opacity: 0, left: '-100%' })),
      ]),
    ]),
  ],
})
export class CalendarComponent extends CalendarComponentBase {
  public showEventsDayWindow = true;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public renderer: Renderer2
  ) {
    super(translate, router, renderer);
  }

  ngAfterViewInit() {
    const periodButton = document.querySelectorAll(
      '.mat-calendar-period-button'
    );

    Array.from(periodButton).forEach((button) => {
      this.renderer.setStyle(button, 'display', 'none');
    });

    super.ngAfterViewInit();
  }

  public dateChanged() {
    this.showEventsDayWindow = false;
    super.dateChanged();
  }

  public today() {
    this.showEventsDayWindow = false;
    super.today();
  }

  public previousClicked() {
    this.showEventsDayWindow = false;
    super.previousClicked();
  }

  public nextClicked() {
    this.showEventsDayWindow = false;
    super.nextClicked();
  }

  dayClicked() {
    this.showEventsDayWindow = true;
  }
}
