import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard as AuthGuardBase } from '@web/base/auth/auth.guard';

@Injectable()
export class AuthGuard extends AuthGuardBase {
  constructor(public authService: AuthService, public router: Router) {
    super(authService, router);
  }
}
