import { MenuService } from './../menu.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../menu-item.model';
import { BaseComponent } from '@web/project/shared/components/base/base.component';

@Component({
  selector: 'web-menu-sidenav',
  templateUrl: './menu-sidenav.component.html',
  styleUrls: ['./menu-sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuSidenavComponent extends BaseComponent implements OnInit {
  public menuSidenav: MenuItem[];

  constructor(
    public translate: TranslateService,
    public menuService: MenuService
  ) {
    super(translate);
  }

  ngOnInit() {
    this.getTranslations(['menu']).then(() => {
      this.menuSidenav = this.menuService.getMenuSidenav();
    });
  }
}
