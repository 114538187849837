import { BaseListWebService } from '@web/base/shared/components/base-list-web/base-list-web.service';
import { Component, OnInit } from '@angular/core';
import { GridContentComponent as GridContentComponentBase } from '@web/base/core/home/grid-content/grid-content.component';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { IResponse } from '@web/project/shared/models/response.model';

@Component({
  selector: 'web-project-home-grid-content',
  templateUrl: './base-list-web.component.html',
  styleUrls: ['./grid-content.component.scss'],
  providers: [BaseListWebService],
})
export class GridContentComponent extends GridContentComponentBase implements OnInit {
  customOptionsHome: OwlOptions;
  items;

  ngOnInit(): void {
    super.ngOnInit();

    this.customOptionsHome = {
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: false,
      navSpeed: 600,
      // rtl: true,
      autoplay: true,
      autoplayTimeout: 3000,
      navText: ['&#8249', '&#8250;'],
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        800: {
          items: 3,
        }
      },
      nav: true,
    };
  }

  getData() {
    this.subscriptions.push(
      this.content$.subscribe((content) => {
        this.items = content.map(c => {
          const image = c.files.find((f) => f.name === 'image');
          c.image = image && image.url ? image.url : null;
          return c;
        })
      })
    );
  }
}
