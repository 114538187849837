import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { TimeInputComponent as TimeInputComponentBase } from '@web/base/shared/components/time-input/time-input.component';

@Component({
  selector: 'web-time-input',
  templateUrl:
    './../../../../base/shared/components/time-input/time-input.component.html',
  styleUrls: [
    './../../../../base/shared/components/time-input/time-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true,
    },
    {
      /**
       * IMPORTANTE -- Aquí definimos quien realiza la validación. En nuestro caso, TimeInputComponent,
       * porque implementa la función 'validate'.
       * Podría ser otra clase cualquiera que implementara esta función.
       */
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true,
    },
  ],
})
export class TimeInputComponent extends TimeInputComponentBase { }
