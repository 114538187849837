import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import { EditorModule } from '@tinymce/tinymce-angular';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { HttpClient /*, HttpClientModule */ } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { FlexLayoutModule } from '@angular/flex-layout';

import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxStarRatingModule } from 'ngx-star-rating';

import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';

import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { RECAPTCHA_KEY } from '../../../environments/environment';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';

export function createTranslateLoader(http) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/base/', suffix: '.json' },
    { prefix: './assets/i18n/project/', suffix: '.json' },
  ]);
}

registerLocaleData(localeEs, 'es-ES', localeEsExtra);

@NgModule({
  imports: [
    CommonModule,
    // HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatBadgeModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatTabsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatListModule,
    MatGridListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSliderModule,
    MatStepperModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    OverlayModule,
    NgxPaginationModule,
    NgxStarRatingModule,
    CarouselModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatMomentDateModule,
    EditorModule,
  ],
  declarations: [],
  exports: [
    CommonModule,
    // HttpClientModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatBadgeModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatTabsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatListModule,
    MatGridListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSliderModule,
    MatStepperModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    OverlayModule,
    NgxPaginationModule,
    NgxStarRatingModule,
    CarouselModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CalendarModule,
    MatMomentDateModule,
    EditorModule,
  ],
  entryComponents: [],
  providers: [
    BreakpointObserver,
    MediaMatcher,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM Y',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM Y',
        },
      },
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: false },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    // { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true, width: '85%', maxWidth: '500px' } },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: RECAPTCHA_KEY } as RecaptchaSettings,
    },
  ],
})
export class SharedCommonModule { }
