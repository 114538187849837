import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit } from '@angular/core';
import { BannersHeaderComponent as BannersHeaderComponentBase } from '@web/base/core/banners/banners-header/banners-header.component';

@Component({
  selector: 'web-project-banners-header',
  templateUrl: './banners-header.component.html',
  styleUrls: ['./banners-header.component.scss'],
})
export class BannersHeaderComponent
  extends BannersHeaderComponentBase
  implements OnInit {
  bannerOptionsHeader: OwlOptions;

  ngOnInit() {
    super.ngOnInit();

    this.bannerOptionsHeader = {
      loop: true,
      mouseDrag: true,
      touchDrag: false,
      pullDrag: false,
      dots: false,
      nav: true,
      navText: [
        '<span class="material-icons">keyboard_arrow_left</span>',
        '<span class="material-icons">keyboard_arrow_right</span>',
      ],
      navSpeed: 1500,
      center: true,
      autoplay: true,
      autoplayTimeout: 8000,
      items: 1,
      // autoWidth: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
      },
    };
  }
}
