import { SigninService } from './dialogs/signin/signin.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { CoreService } from '@web/base/core/core.service';
import { AuthService } from '@web/base/auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IResponse } from '@web/project/shared/models/response.model';
import { Router } from '@angular/router';

@Component({
  selector: 'web-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  public HEADER_HEIGHT = ConstantsProject.HEADER_HEIGHT;

  public scrollOffset;

  public searchBarActive = false;
  public isMobile: boolean;

  formSearch: FormGroup;
  resultSearch;
  MIN_LENGTH_TO_SEARCH = ConstantsProject.MIN_LENGTH_TO_SEARCH;

  environment = environment;

  ELEMENT_TYPE_COLLEGIATE = ConstantsProject.ELEMENT_TYPE_COLLEGIATE;
  ELEMENT_TYPE_PRECOLLEGIATE = ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE;

  @HostListener('window:scroll', ['$event'])
  onScroll($event: any) {
    this.scrollOffset = $event.srcElement.children[0].scrollTop;
  }

  constructor(
    public translate: TranslateService,
    public coreService: CoreService,
    public authService: AuthService,
    public signinService: SigninService,
    public router: Router,
    public sc: ScrollDispatcher
  ) {
    super(translate);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.coreService.isMobile$.subscribe((val) => (this.isMobile = val))
    );

    this.getTranslations(['search']).then(() => {
      this.createForm();
    });
  }

  openMenu() {
    this.coreService.setSidenavStatus(true);
  }

  createForm() {
    this.formSearch = new FormGroup({
      text: new FormControl('', [
        Validators.required,
        Validators.minLength(this.MIN_LENGTH_TO_SEARCH),
      ]),
    });

    this.formSearch.controls['text'].valueChanges
      .pipe(debounceTime(800))
      .subscribe((v) => {
        if (v && v.length >= this.MIN_LENGTH_TO_SEARCH) {
          this.coreService.loadSearch(v).subscribe((res: IResponse) => {
            this.resultSearch = res.result.items.map((i) => {
              if (i.files) {
                const image = i.files.find((f) => f.name === 'image');
                i.image = image && image.url ? image.url : null;
              }
              i.titleCategory =
                i.titleCategory ||
                this.translations['search']['categories'][i.type];
              return i;
            });
          });
        } else {
          this.resultSearch = null;
        }
      });
  }

  searchAdvanced() {
    if (this.formSearch.controls['text'].value) {
      this.resultSearch = null;
      this.router.navigateByUrl(
        '/buscador/' + this.formSearch.controls['text'].value
      );
      // this.formSearch.controls['text'].reset();
    }
  }
}
