import { Component, Input } from '@angular/core';
import { BreadcrumbComponent as BreadcrumbComponentBase } from '@web/base/core/breadcrumb/breadcrumb.component';

@Component({
  selector: 'web-project-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent extends BreadcrumbComponentBase {
  @Input('hide') hide: boolean = false;
}
