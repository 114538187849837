import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterState,
  RouterStateSnapshot,
} from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard {
  constructor(public authService: AuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    this.authService
      .isLoggedIn()
      .pipe(
        tap((v) => {
          if (v === false) {
            this.router.navigate(['login'], {
              queryParams: { returnUrl: routerState.url },
            });
          }
        })
      )
      .subscribe();

    return this.authService.isLoggedIn();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ) {
    this.authService
      .isLoggedIn()
      .pipe(
        tap((v) => {
          if (v === false) {
            this.router.navigate(['login'], {
              queryParams: { returnUrl: routerState.url },
            });
          }
        })
      )
      .subscribe();

    return this.authService.isLoggedIn();
  }
}
