import { Injectable } from '@angular/core';
import {
  MatDialogRef,
  MatDialogConfig,
  MatDialog,
} from '@angular/material/dialog';

import { RememberPasswordComponent } from './remember-password.component';
import { RememberPasswordService as RememberPasswordServiceBase } from '@web/base/core/header/dialogs/remember-password/remember-password.service';

@Injectable()
export class RememberPasswordService extends RememberPasswordServiceBase {
  openDialog() {
    let dialogRef: MatDialogRef<RememberPasswordComponent>;
    dialogRef = this.dialog.open(RememberPasswordComponent, {
      panelClass: 'dialog',
    });
    return dialogRef.afterClosed();
  }
}
