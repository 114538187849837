import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { URL } from "../../../../environments/environment";

@Injectable()
export class NewslettersSubscriptionService {
  public pathBase: string;

  constructor(public http: HttpClient) { }

  create(item) {
    const url = `${URL}/agrupations-members`;
    console.log("----------------------------");
    console.log("url -> ", url);
    console.log("----------------------------");

    return this.http.post(url, item);
  }
}
