import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { IAttachment } from '@web/project/shared/models/attachment.model';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { downloadURL } from '@web/base/shared/utils';

@Component({
  selector: 'web-base-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent
  extends BaseComponent
  implements OnInit, OnChanges {
  @Input() name: string;
  @Input() titleAttachment: string;
  @Input() type: number;
  @Input() formData: FormData;
  @Input() attachment: IAttachment = {};
  @Input() acceptedFiles = '';
  @Input() disabled = false;

  @Input() viewMode = 1;
  // 1: Vista por defecto
  // 2: Vista alternativa (1 imagen únicamente, p.e. Imagen del colegiado). Se usa junta con el type=2 (imagen)

  @Input() maxSizeAttachment = ConstantsProject.MAX_SIZE_ATTACHMENTS;

  @Output() fileChanged: EventEmitter<void> = new EventEmitter<void>();

  public backgroundImg;

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public sanitizer: DomSanitizer
  ) {
    super(translate, snackbar);
  }

  ngOnInit() {
    // Si el tipo no es IMAGEN, usamos siempre el formato listado para representar el adjunto.
    if (this.type !== 2) {
      this.viewMode = 1;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.viewMode === 2 && this.attachment) {
      this.backgroundImg =
        this.attachment.hash || this.attachment.pending
          ? this.sanitizer.bypassSecurityTrustStyle(
            'url(' + this.attachment.url + ')'
          )
          : this.sanitizer.bypassSecurityTrustStyle(
            'url(../../../../assets/img/base/document.svg)'
          );
    }
  }

  download() {
    downloadURL(this.attachment.url, this.attachment.title, true);
  }

  del() {
    if (this.attachment.hash) {
      this.attachment.delete = !this.attachment.delete;
    } else {
      // Corrección eliminar adjunto de componente.
      Object.keys(this.attachment).forEach((k) => {
        delete this.attachment[k];
      });

      if (this.formData.has('file_' + this.name)) {
        this.formData.delete('file_' + this.name);
      }
    }
    this.fileChanged.emit();
  }

  onFile(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      this.attachment = Object.assign(this.attachment, {
        name: this.name,
        pending: true,
        title: this.titleAttachment,
        type: this.type,
        hash:
          this.attachment && this.attachment.hash ? this.attachment.hash : '',
        url: e.target.result,
      });

      this.fileChanged.emit();

      // Actualizar la imagen de fondo
      if (this.viewMode === 2) {
        this.backgroundImg = e.target.result
          ? this.sanitizer.bypassSecurityTrustStyle(
            'url(' + e.target.result + ')'
          )
          : this.sanitizer.bypassSecurityTrustStyle(
            'url(../../../../assets/img/base/document.svg)'
          );
      }
    };

    if (this.formData.has('file_' + this.name)) {
      this.formData.set('file_' + this.name, file, file.name);
    } else {
      this.formData.append('file_' + this.name, file, file.name);
    }
  }
}
