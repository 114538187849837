import { EditAttachmentComponent as EditAttachmentComponentBase } from '@web/base/shared/components/attachments-handle/dialogs/edit-attachment/edit-attachment.component';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'web-edit-attachment',
  templateUrl:
    './../../../../../../base/shared/components/attachments-handle/dialogs/edit-attachment/edit-attachment.component.html',
  styleUrls: [
    './../../../../../../base/shared/components/attachments-handle/dialogs/edit-attachment/edit-attachment.component.scss',
  ],
})
export class EditAttachmentComponent extends EditAttachmentComponentBase {
  constructor(
    public translate: TranslateService,
    // public sharedService: SharedService,
    // public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<EditAttachmentComponent>
  ) {
    super(translate, dialogRef);
  }
}
