import { Component, Input, OnInit } from '@angular/core';
import { AttachmentsHandleComponent as AttachmentsHandleComponentBase } from '@web/base/shared/components/attachments-handle/attachments-handle.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AttachmentsHandleDialogService } from '@web/project/shared/components/attachments-handle/dialogs/attachments-handle-dialog.service';

@Component({
  selector: 'web-attachments-handle',
  templateUrl: './attachments-handle.component.html',
  styleUrls: [
    './../../../../base/shared/components/attachments-handle/attachments-handle.component.scss',
    './attachments-handle.component.scss',
  ],
  providers: [AttachmentsHandleDialogService],
})
export class AttachmentsHandleComponent
  extends AttachmentsHandleComponentBase
  implements OnInit {
  @Input() title = '';
  @Input() required: boolean = false;

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public attachmentsHandleDialogService: AttachmentsHandleDialogService
  ) {
    super(translate, snackbar, attachmentsHandleDialogService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.getTranslations(['attachments']).then(() => {
      if (!this.title) {
        this.title = this.translations['attachments']['title'];
      }
    });
  }
}
