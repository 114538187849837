import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { LoadingButtonComponent as LoadingButtonComponentBase } from '@web/base/shared/components/loading-button/loading-button.component';

@Component({
  selector: 'web-loading-button',
  templateUrl:
    './../../../../base/shared/components/loading-button/loading-button.component.html',
  styleUrls: [
    './../../../../base/shared/components/loading-button/loading-button.component.scss',
  ],
})
export class LoadingButtonComponent extends LoadingButtonComponentBase {
  constructor(public translate: TranslateService) {
    super(translate);
  }
}
