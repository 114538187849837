import { OwlOptions } from 'ngx-owl-carousel-o';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '../../core.service';

@Component({
  selector: 'web-banners-header',
  templateUrl: './banners-header.component.html',
  styleUrls: ['./banners-header.component.scss'],
})
export class BannersHeaderComponent extends BaseComponent implements OnInit {
  @Input() banners;

  bannerOptions: OwlOptions;

  constructor(translate: TranslateService, public coreService: CoreService) {
    super(translate);
  }

  ngOnInit() {
    // this.subscriptions.push(
    //   this.coreService.bannersHeader$.subscribe(res => {
    //     if (res) {
    //       this.banners = res;
    //     }
    //   })
    // );

    this.bannerOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      center: true,
      autoplay: true,
      items: 1,
      autoWidth: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
      },
    };
  }

  // shuffle(banners) {
  //   let i = banners.length;
  //   while (i--) {
  //     const ri = Math.floor(Math.random() * (i + 1));
  //     [banners[i], banners[ri]] = [banners[ri], banners[i]];
  //   }
  //   return banners;
  // }
}
