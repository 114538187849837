import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { AuthService } from './base/auth/auth.service';
import { ConstantsProject } from './project/shared/constants.class';

@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(public router: Router, public authService: AuthService) {
    // Si el usuario está logueado y no ha aceptado las condiciones, no puede salir de la pantalla de aceptar las condiciones.
    router.events.subscribe((event: Event) => {
      if (
        event instanceof NavigationStart &&
        event.url.indexOf('primer-acceso') < 0 &&
        this.authService.user &&
        !this.authService.user.conditionsAccepted
      ) {
        if (
          this.authService.user.elementTypeID ===
          ConstantsProject.ELEMENT_TYPE_COLLEGIATE
        ) {
          this.router.navigate(['mi-zona-privada', 'primer-acceso']);
        } else if (
          this.authService.user.elementTypeID ===
          ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE
        ) {
          this.router.navigate([
            'mi-zona-privada-precolegial',
            'primer-acceso',
          ]);
        } else if (
          this.authService.user.elementTypeID ===
          ConstantsProject.ELEMENT_TYPE_RETIRED
        ) {
          this.router.navigate(['mi-zona-privada-bajas', 'primer-acceso']);
        } else if (
          this.authService.user.elementTypeID ===
          ConstantsProject.ELEMENT_TYPE_VOLUNTARY
        ) {
          // this.router.navigate(['mi-zona-privada-bajas', 'primer-acceso']);
        }
      }
    });
  }

  public ngOnInit(): void { }
}
