import { BehaviorSubject, Subject } from 'rxjs';
import { HomeComponent as HomeComponentBase } from '@web/base/core/home/home.component';
import { Component, OnInit } from '@angular/core';
import { IResponse } from '@web/project/shared/models/response.model';
import { order } from '@web/base/shared/utils';

import { Moment } from 'moment';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-project-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends HomeComponentBase implements OnInit {
  public events: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(
    []
  );
  public events$ = this.events.asObservable();

  public loadingEvents: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public loadingEvents$ = this.loadingEvents.asObservable();

  public totalCoursesItems = 0;
  public coursesContent = new Subject<Array<any>>();
  public coursesContent$ = this.coursesContent.asObservable();

  public totalShortNewsItems = 0;
  public shortNews = new Subject<Array<any>>();
  public shortNews$ = this.shortNews.asObservable();

  // public bannersHeaderSecondary = [];

  bannersHeaderLoaded = false; // Controla la carga de los banners superiores (slider)
  // bannersHeaderSecondaryLoaded = false; // Controla la carga de los banners superiores secundarios (slider)

  ngOnInit() {
    this.coreService.loadCover().subscribe((v: IResponse) => {
      // Banners carrusel superior

      this.bannersHeader = v.result.banners
        .filter((b) => b.bannerPositionID === ConstantsProject.BANNER_TOP)
        .sort((a, b) => order(a, b));

      this.bannersHeaderLoaded = true;

      // Banners carrusel superior secundario
      // this.bannersHeaderSecondary = v.result.banners
      //   .filter(
      //     (b) => b.bannerPositionID === ConstantsProject.BANNER_TOP_SECONDARY
      //   )
      //   .sort((a, b) => order(a, b));

      // this.bannersHeaderSecondaryLoaded = true;

      // Elementos de la portada

      // this.sliderContent.next(v.result.sliderContent);
      // this.listContent.next(v.result.listContent);
      this.gridContent.next(v.result.gridContent);
      this.coursesContent.next(v.result.coursesContent);
      this.shortNews.next(v.result.shortNews);

      // this.totalSliderItems = +v.result.sliderContent.length;
      this.totalGridItems = +v.result.gridContent.length;
      // this.totalListItems = +v.result.listContent.length;
      this.totalCoursesItems = +v.result.coursesContent.length;
      this.totalShortNewsItems = +v.result.shortNews.length;
    });

  }

  dateSelected(value: Moment) {
    if (value) {
      this.loadCalendar(value);
    }
  }

  loadCalendar(value: Moment) {
    this.events.next([]);
    setTimeout(() => this.loadingEvents.next(true));
    this.coreService
      .loadEventsByMonthAndYear(+value.format('MM'), +value.format('YYYY'))
      .subscribe(
        (res: IResponse) => {
          this.events.next(res.result);
          setTimeout(() => this.loadingEvents.next(false));
        },
        (err) => {
          console.log('err', err);
          setTimeout(() => this.loadingEvents.next(false));
        }
      );
  }
}
