import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { tap } from 'rxjs/operators';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { AuthService } from '@web/base/auth/auth.service';

@Injectable()
export class AuthRetiredGuard {
  constructor(public authService: AuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    this.authService
      .isLoggedIn()
      .pipe(
        tap((v) => {
          if (
            v === false ||
            (this.authService.user &&
              this.authService.user.elementTypeID !==
              ConstantsProject.ELEMENT_TYPE_RETIRED)
          ) {
            this.router.navigate(['login'], {
              queryParams: {
                returnUrl: routerState.url,
                type: ConstantsProject.ELEMENT_TYPE_RETIRED,
              },
            });
          }
        })
      )
      .subscribe();

    return this.authService.isLoggedIn();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ) {
    this.authService
      .isLoggedIn()
      .pipe(
        tap((v) => {
          if (
            v === false ||
            (this.authService.user &&
              this.authService.user.elementTypeID !==
              ConstantsProject.ELEMENT_TYPE_RETIRED)
          ) {
            this.router.navigate(['login'], {
              queryParams: {
                returnUrl: routerState.url,
                type: ConstantsProject.ELEMENT_TYPE_RETIRED,
              },
            });
          }
        })
      )
      .subscribe();

    return this.authService.isLoggedIn();
  }
}
