import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import * as fromApp from '../../../../../base/store/app.reducers';
import { AuthService } from '../../../../../project/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { SigninComponent as SigninComponentBase } from '@web/base/core/header/dialogs/signin/signin.component';

import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import * as AuthActions from '../../../../../base/auth/store/auth.actions';
import { RememberPasswordService } from '../remember-password/remember-password.service';

@Component({
  selector: 'web-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent extends SigninComponentBase implements OnInit {
  ELEMENT_TYPE_RETIRED = ConstantsProject.ELEMENT_TYPE_RETIRED;
  ELEMENT_TYPE_VOLUNTARY = ConstantsProject.ELEMENT_TYPE_VOLUNTARY;
  public userTypes = ConstantsProject.USERS_TYPES;
  textType;

  constructor(
    public translate: TranslateService,
    public store: Store<fromApp.AppState>,
    public authService: AuthService,
    public rememberPasswordService: RememberPasswordService,
    public router: Router,
    public dialogRef: MatDialogRef<SigninComponent>
  ) {
    super(translate, store, authService, rememberPasswordService);
    this.authService.errorLogin = '';
  }

  ngOnInit() {
    super.ngOnInit();

    this.getTranslations(['core']).then(() => {
      this.textType = this.translations['core']['signin']['usernameCollegiate'];

      this.form.controls['type'].valueChanges.subscribe((data) => {
        if (data === ConstantsProject.ELEMENT_TYPE_RETIRED) {
          this.textType =
            this.translations['core']['signin']['usernameRetired'];
        } else if (data === ConstantsProject.ELEMENT_TYPE_VOLUNTARY) {
          this.textType =
            this.translations['core']['signin']['usernameVoluntary'];
        } else if (data === ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE) {
          this.textType =
            this.translations['core']['signin']['usernamePrecollegiate'];
        } else {
          this.textType =
            this.translations['core']['signin']['usernameCollegiate'];
        }
      });
    });
  }

  login() {
    if (this.form.status === 'VALID') {
      this.store.dispatch(new AuthActions.TrySignin(this.form.value));
    }
  }
}
