import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { getCookie } from '@web/project/shared/utils';

@Component({
  selector: 'web-project-multimedia-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class MultimediaVideoDialogComponent implements OnInit, AfterViewInit {

  cookie = null;
  COOKIES_CONSENT = ConstantsProject.COOKIES_CONSENT;
  showVideo = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultimediaVideoDialogComponent>,
    private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    if (this.cdRef) {
      this.cookie = JSON.parse(getCookie(this.COOKIES_CONSENT));
      if (this.cookie && this.cookie.functionals) {
        this.showVideo = true;
        this.cdRef.detectChanges();
      }
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
