import { OnDestroy, Component } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Subscription, Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { SimpleSnackbarComponent } from "../simple-snackbar/simple-snackbar.component";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";

@Component({
  selector: "web-base-base",
  template: "",
})
export class BaseComponent implements OnDestroy {
  public error = false; // Para las pruebas unitarias.

  translations: Array<any> = [];
  subscriptions: Array<Subscription> = [];

  constructor(
    public translate: TranslateService,
    public snackbar?: MatSnackBar,
    public dialog?: MatDialog
  ) {
    const locale = localStorage.getItem("locale");

    if (!locale) {
      translate.use("es");
    } else {
      translate.use(locale);
    }

    this.getTranslations(["general"]);
  }

  setLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem("locale", lang);
    window.location.reload();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  getTranslations(keys: Array<string>) {
    return new Promise<void>((resolve, reject) => {
      if (keys.length) {
        keys.forEach((k, index) => {
          this.translate.get(k).subscribe(
            (res) => {
              this.translations[k] = res;
              if (index === keys.length - 1) {
                resolve();
              }
            },
            (err) => {
              console.log("error get translation", err);
              reject();
            }
          );
        });
      } else {
        resolve();
      }
    });
  }

  callFunction(event) {
    this[event.functionName](event.event);
  }

  openSnackbar(
    message: string,
    type?: number
  ): MatSnackBarRef<SimpleSnackbarComponent> {
    let panelClass;

    type = type || SimpleSnackbarComponent.TYPE_INFO;

    switch (type) {
      case SimpleSnackbarComponent.TYPE_ERROR:
        panelClass = ["snackbar", "snackbar-error"];
        break;
      case SimpleSnackbarComponent.TYPE_OK:
        panelClass = ["snackbar", "snackbar-ok"];
        break;
      case SimpleSnackbarComponent.TYPE_INFO:
      default:
        panelClass = ["snackbar", "snackbar-info"];
        break;
    }

    return this.snackbar.openFromComponent(SimpleSnackbarComponent, {
      data: {
        content: message,
      },
      panelClass: panelClass,
      horizontalPosition: "left",
      duration: 5000,
    });
  }

  openSimpleDialog(
    title: string,
    content: string,
    type?: number
  ): Observable<any> {
    let panelClass;

    console.log("TYPE ->", type);

    type = type || SimpleDialogComponent.TYPE_DEFAULT;

    let confirmText = this.translations["general"].confirmText;
    let rejectText = this.translations["general"].rejectText;
    let cancelText = this.translations["general"].cancelText;

    switch (type) {
      case SimpleDialogComponent.TYPE_YES_NO:
        panelClass = ["dialog", "dialog-yes-no"];
        confirmText = this.translations["general"].yesText;
        // cancelText = this.translations['general'].noText;
        break;
      case SimpleDialogComponent.TYPE_CONFIRM:
        panelClass = ["dialog", "dialog-confirm"];
        break;
      case SimpleDialogComponent.TYPE_ERROR:
        panelClass = ["dialog", "dialog-error"];
        break;
      case SimpleDialogComponent.TYPE_OK:
        panelClass = ["dialog", "dialog-ok"];
        break;
      case SimpleDialogComponent.TYPE_INFO:
        break;
      case SimpleDialogComponent.TYPE_DEFAULT:
      default:
        panelClass = ["dialog", "dialog-default"];
        break;
    }

    const dialog = this.dialog.open(SimpleDialogComponent, {
      panelClass: panelClass,
      disableClose: true,
      data: {
        type: type,
        title: title,
        content: content,
        confirmText: confirmText,
        rejectText: rejectText,
        cancelText: cancelText,
        okText: this.translations["general"].okText,
      },
    });

    return dialog.afterClosed();
  }
}
