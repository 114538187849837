import {
  Component,
  OnInit,
  Input,
  HostBinding,
  Output,
  ElementRef,
  OnDestroy,
  EventEmitter,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { SharedService } from '@web/project/shared/shared.service';
import { debounceTime, map } from 'rxjs/operators';
import { ITableEstructure } from '@web/project/shared/models/table-structure.model';
import { ElementSearchDialogService } from './dialogs/element-search-dialog.service';
import { BaseComponent } from '@web/project/shared/components/base/base.component';

@Component({
  selector: 'web-base-element-search-input',
  templateUrl: './element-search-input.component.html',
  styleUrls: ['./element-search-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: ElementSearchInputComponent },
    ElementSearchDialogService,
  ],
})
export class ElementSearchInputComponent
  extends BaseComponent
  implements MatFormFieldControl<any>, OnInit, OnDestroy {
  static nextId = 0;

  @HostBinding('id')
  id = `web-element-search-input-${ElementSearchInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input() errorState;

  @Input() loadFunction: string;
  @Input() display: (element: any) => string;
  @Input() key: string;
  @Input() canCreate = true;
  @Input() structure: Array<ITableEstructure>;
  @Input() extraFilter: any = {};
  @Input() classList: string;
  @Input() showTextField = true;
  @Input() formSearch: FormGroup;
  @Input() templateSearch: TemplateRef<any>;
  @Input() clear: Observable<void>;

  @ViewChild('input', { static: false }) input: ElementRef;

  elementLoaded = false;
  loading = false;

  form: FormGroup;

  stateChanges = new Subject<void>();
  focused = false;
  ngControl = null;
  controlType = 'web-element-search-input';

  public _disabled = false;
  public _placeholder: string;

  elements = new BehaviorSubject<Array<any>>(null);
  elements$ = this.elements.asObservable();

  elementSelected = null;

  get empty() {
    const n = this.form.value;
    return !n.elementID;
  }

  get shouldPlaceholderFloat() {
    return this.focused || !this.empty;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  public _required = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    if (this._disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.stateChanges.next();
  }

  @Input()
  get value(): any | null {
    const n = this.form.value;
    if (n[this.key]) {
      return {
        elementID: n[this.key],
      };
    }
    return null;
  }
  set value(t: any | null) {
    t = t || {};

    if (t[this.key]) {
      this.form.patchValue({
        // elementID: t.elementID,
        elementID: t,
      });
      this.elementSelected = t;
      this.form.controls['elementID'].updateValueAndValidity();
    }

    // this.elements.next([t]);
    this.elements.next(null);
    this.stateChanges.next();
  }

  @Output()
  selectedElement: EventEmitter<any> = new EventEmitter();

  // @Output()
  // finished: EventEmitter<number> = new EventEmitter();

  constructor(
    fb: FormBuilder,
    public fm: FocusMonitor,
    public elRef: ElementRef,
    public sharedService: SharedService,
    public elementSearchInputDialogService: ElementSearchDialogService,
    public translate: TranslateService
  ) {
    super(translate);

    this.form = new FormGroup({
      elementID: new FormControl(null, [this.validateElement.bind(this)]),
      // elementSelected: new FormControl(null)
    });

    this.form.controls['elementID'].valueChanges
      .pipe(
        map((value) => {
          if (!(value instanceof Object) && value) {
            this.loading = true;
          } else if (!value) {
            this.elements.next(null);
            this.loading = false;
          }
          return value;
        }),
        debounceTime(800)
      )
      .subscribe((value) => {
        // if (!(value instanceof Object) && value !== null) {
        if (!(value instanceof Object) && value) {
          let search;
          if (this.formSearch) {
            search = Object.assign({}, this.formSearch.value);
            search.page = 0;
            search.quantity = 50;
            search.filter = value;
          } else {
            search = { page: 0, quantity: 50, filter: value };
          }

          this.sharedService[this.loadFunction](search).subscribe(
            (res: any) => {
              this.elements.next(
                res.result.items.map((i) => {
                  i.displayValue = this.display(i);
                  return i;
                })
              );

              this.loading = false;
            },
            (err) => {
              this.loading = false;
            }
          );
        } else if (!value) {
          this.elements.next(null);
          this.loading = false;
        }
      });

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    if (this.clear) {
      this.subscriptions.push(
        this.clear.subscribe(() => {
          this.clearValue();
        })
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  validateElement(c: FormControl) {
    return this.required
      ? this.elementSelected
        ? null
        : { required: true }
      : null;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  onSelectElement(event: MatAutocompleteSelectedEvent) {
    // this.selectedElement.emit(event.option.value[this.key]);
    this.selectedElement.emit(event.option.value);
    this.elementSelected = event.option.value;
    this.form.controls['elementID'].updateValueAndValidity();
  }

  displayFn(element?: any): string | undefined {
    return element && element[this.key] ? this.display(element) : undefined;
  }

  clearValue() {
    this.form.controls['elementID'].setValue(null);
    this.elements.next([]);
    this.selectedElement.emit(null);
    this.elementSelected = null;
    this.form.controls['elementID'].updateValueAndValidity();
  }

  openDialog() {
    if (this.key) {
      this.elementSearchInputDialogService
        .openDialog(
          this.structure,
          this.loadFunction,
          this.placeholder,
          this.formSearch,
          this.templateSearch,
          this.canCreate,
          this.extraFilter
        )
        .subscribe((res) => {
          if (res !== false) {
            if (res[this.key]) {
              this.form.patchValue({
                // elementID: t.elementID,
                elementID: res,
              });
            }

            // this.elements.next([res]);
            this.elements.next(null);
            this.stateChanges.next();

            // this.selectedElement.emit(res[this.key]);
            this.selectedElement.emit(res);
            this.elementSelected = res;
            this.form.controls['elementID'].updateValueAndValidity();
          }
        });
    }
  }

  public focus() {
    this.input.nativeElement.focus();
  }
}
