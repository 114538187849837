import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, Output } from '@angular/core';
import { AttachmentComponent as AttachmentComponentBase } from '@web/base/shared/components/attachment/attachment.component';
import { EventEmitter } from 'events';

@Component({
  selector: 'web-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: [
    './../../../../base/shared/components/attachment/attachment.component.scss',
  ],
})
export class AttachmentComponent extends AttachmentComponentBase {
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() forceDelete = false; // Para forzar que se pueda eliminar este o no deshabilitado.

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public sanitizer: DomSanitizer
  ) {
    super(translate, snackbar, sanitizer);
  }
}
