import { SharedService } from '@web/project/shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { Component } from '@angular/core';
import { BaseWebComponent as BaseWebComponentBase } from '@web/base/shared/components/base-web/base-web.component';
import { ConstantsProject } from '../../constants.class';

@Component({
  template: '',
})
export class BaseWebComponent extends BaseWebComponentBase {
  constructor(
    public translate: TranslateService,
    public snackbar?: MatSnackBar,
    public dialog?: MatDialog,
    public meta?: Meta,
    public title?: Title,
    public route?: ActivatedRoute,
    public sharedService?: SharedService
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  setMetas(title, description?, keywords?, image?) {
    if (!this.metasAssigned) {
      setTimeout(() => {
        this.getTranslations([]).then(() => {
          var siteName = this.translations['general']['projectName'];
          var contentTitle = title
            ? title
            : this.translations['general']['projectName'];
          var contentDescription = description ? description : title;
          var contentKeywords =
            keywords || this.translations['general']['keywords'];
          var contentImage =
            image || 'https://codegra.es/assets/img/project/imagen_redes.jpg';
          var url = window.location.href;

          // Metas OG ---------
          // this.meta.updateTag({ property: 'fb:app_id', content: this.FACEBOOK_APP_ID });
          this.meta.updateTag({ property: 'og:image', content: contentImage });
          this.meta.updateTag({
            property: 'og:image:secure_url',
            content: contentImage,
          });
          this.meta.updateTag({
            property: 'og:image:alt',
            content: contentTitle,
          });
          // this.meta.updateTag({ property: 'og:type', content: 'website' });
          this.meta.updateTag({ property: 'og:type', content: 'article' });
          // this.meta.updateTag({ property: 'og:image:width', content: '300'});
          // this.meta.updateTag({ property: 'og:image:height', content: '200' });
          this.meta.updateTag({ property: 'og:site_name', content: siteName });
          this.meta.updateTag({ property: 'og:url', content: url });
          this.meta.updateTag({ property: 'og:title', content: contentTitle });
          this.meta.updateTag({
            property: 'og:description',
            content: contentDescription,
          });

          // Metas generales ---------
          this.title.setTitle(title);
          this.meta.updateTag({ name: 'title', content: contentTitle });
          this.meta.updateTag({
            name: 'description',
            content: contentDescription,
          });
          this.meta.updateTag({ name: 'keywords', content: contentKeywords });
          this.meta.updateTag({ name: 'image', content: contentImage });

          // Metas de twitter ---------
          this.meta.updateTag({
            name: 'twitter:card',
            content: 'summary_large_image',
          });

          if (ConstantsProject.TWITTER) {
            this.meta.updateTag({
              name: 'twitter:site',
              content: ConstantsProject.TWITTER,
            });
            this.meta.updateTag({
              name: 'twitter:creator',
              content: ConstantsProject.TWITTER,
            });
          }

          this.meta.updateTag({ name: 'twitter:title', content: contentTitle });
          this.meta.updateTag({
            name: 'twitter:description',
            content: contentDescription,
          });
          this.meta.updateTag({
            name: 'twitter:text:summary_photo_image_alt_text',
            content: contentTitle,
          });
          this.meta.updateTag({ name: 'twitter:image', content: contentImage });
          this.meta.updateTag({
            name: 'twitter:image:src',
            content: contentImage,
          });

          if (this.translations['general']) {
            this.meta.updateTag({
              name: 'description',
              content: contentDescription,
            });
          }
        });
      });
    }
  }

  // setMetas(title, description?) {
  //   setTimeout(() => {
  //     if (this.translations['general']) {
  //       this.title.setTitle(this.translations['general']['projectName'] + ' :: ' + title);
  //       this.meta.updateTag({
  //         name: 'description', content: description ? description : title
  //       });
  //     }
  //   }, 50);
  // }
}
