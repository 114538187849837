import { Component } from '@angular/core';
import { ChipsAutocompleteInputComponent as ChipsAutocompleteInputComponentBase } from '@web/base/shared/components/chips-autocomplete-input/chips-autocomplete-input.component';

@Component({
  selector: 'web-chips-autocomplete-input',
  templateUrl:
    './../../../../base/shared/components/chips-autocomplete-input/chips-autocomplete-input.component.html',
  styleUrls: [
    './../../../../base/shared/components/chips-autocomplete-input/chips-autocomplete-input.component.scss',
  ],
})
export class ChipsAutocompleteInputComponent extends ChipsAutocompleteInputComponentBase { }
