import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { IImageGallery } from '../../models/image-gallery.model';
import { IVideo } from '../../models/video.model';
import { order } from '@web/base/shared/utils';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MultimediaEmbedVideoService } from './embed-video-service';
import { MultimediaVideoDialogComponent } from './video-dialog/video-dialog.component';
import { MultimediaImageGalleryService } from './image-gallery/service/image-gallery.service';

@Component({
  selector: 'web-project-multimedia-module',
  templateUrl: './multimedia-module.component.html',
  styleUrls: ['./multimedia-module.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate('250ms ease-in'))
    ])
  ]
})
export class MultimediaModuleComponent implements OnInit, AfterViewInit {

  public IMAGE = 2;
  public VIDEO = 3;

  /** Propieddades del componente **/
  @Input() images: IImageGallery[];
  @Input() videos: IVideo[];
  @Input() title: string;
  @Input() order: 'asc' | 'desc' = 'asc';
  @Input() showTitle: boolean;

  @ViewChild('imageGallery', { static: false }) imageGallery: ElementRef;

  public multimedias: Array<any> = [];

  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public galleryService: MultimediaImageGalleryService,
    public multimediaEmbedVideoService: MultimediaEmbedVideoService
  ) { }

  ngOnInit() {
    // Imagenes
    this.images.map(element => { element.loaded = false; });
    if (this.order === 'asc') {
      this.images.sort((a, b) => a.order - b.order);
    } else {
      this.images.sort((a, b) => b.order - a.order);
    }

    // Videos
    if (this.order === 'asc') {
      this.videos.sort((a, b) => order(a, b));
    } else {
      this.videos.sort((a, b) => order(a, b, 'order', 'desc'));
    }

    this.videos.map(async (v, i) => {
      this.multimediaEmbedVideoService.url_image(v.videoUrl)
        .then(thumbnail => {
          this.videos[i].thumbnail = thumbnail;
        });
    });

    this.multimedias = [...this.images, ...this.videos];
  }

  ngAfterViewInit() {

  }

  onLoadImage(imageIndex) {
    this.images[imageIndex].loaded = true;
  }

  openImage(img: IImageGallery, galleryIndex: number, imageIndex: number) {
    this.galleryService.open({
      image: img,
      gallery: this.images.length > 1 ? this.images : null,
      imageIndex: imageIndex
    });
  }

  loadVideo(video: IVideo) {
    let tipoVideo = (video.videoUrl.indexOf('/shorts/') > -1) ? 'youtube-shorts' : 'normal';
    this.dialog.open(MultimediaVideoDialogComponent, {
      panelClass: ['dialog', 'dialog-sm', 'video-dialog'],
      data: {
        title: video.title,
        video: this.sanitizer.bypassSecurityTrustResourceUrl(this.multimediaEmbedVideoService.embed_video(video.videoUrl)),
        tipo: tipoVideo
      },
      width: tipoVideo === 'youtube-shorts' ? '455px' : '75%'
    });
  }
}
