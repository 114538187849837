import { AppComponent } from "./app.component";
import { AppModule } from "./app.module";
import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  bootstrap: [AppComponent],

  imports: [
    BrowserModule.withServerTransition({ appId: "web-root" }),
    AppModule,
    BrowserAnimationsModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: false }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: "es-ES" }],
})
export class AppBrowserModule { }
