import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromApp from '../../store/app.reducers';
import * as fromAuth from '../store/auth.reducers';
import * as AuthActions from '../store/auth.actions';
import { SharedService } from '@web/project/shared/shared.service';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { RememberPasswordService } from '@web/base/core/header/dialogs/remember-password/remember-password.service';
import { BaseWebComponent } from '@web/project/shared/components/base-web/base-web.component';

@Component({
  selector: 'web-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent extends BaseWebComponent implements OnInit {
  section = 'signin';

  breadcrumbs = [{ label: 'signin', url: '', navigable: false }];

  public form: FormGroup;

  ELEMENT_TYPE_COLLEGIATE = ConstantsProject.ELEMENT_TYPE_COLLEGIATE;
  ELEMENT_TYPE_PRECOLLEGIATE = ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE;

  public userTypes = ConstantsProject.USERS_TYPES;

  authState$: Observable<fromAuth.State>;

  // Tipo de usuario a loguear, si viene fijo por la URL.
  public type;

  constructor(
    public store: Store<fromApp.AppState>,
    public authService: AuthService,
    public rememberPasswordService: RememberPasswordService,
    public sharedService: SharedService,
    public router: Router,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public meta: Meta,
    public title: Title
  ) {
    super(translate, null, null, meta, title, route, sharedService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.getTranslations(['core', 'signin']).then(() => {
      this.breadcrumbs[0].label = this.translations['signin']['title'];
      this.setBreadcrumbs();

      this.userTypes.map((t) => {
        t.text = this.translations['core']['signin'][t.name];
        return t;
      });
    });

    // if (this.authService.isLoggedIn()) {
    //   this.router.navigate(['home']);
    // }

    // this.subscriptions.push(this.authService.loggedIn$.subscribe(v => {
    //   if (v === true) {
    //     this.router.navigate(['home']);
    //   }
    // }));

    this.authState$ = this.store.select('auth');

    this.form = new FormGroup({
      type: new FormControl(this.userTypes[0].value),
      username: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
        ])
      ),
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(60),
        ])
      ),
    });

    this.route.queryParams.subscribe((v) => {
      if (v.type) {
        this.type = v.type;
        this.form.controls['type'].setValue(+this.type);
      }
    });
  }

  login() {
    if (this.form.valid) {
      this.store.dispatch(new AuthActions.TrySignin(this.form.value));
    }
  }

  openRememberPasswordDialog() {
    // this.dialogRef.close();
    this.rememberPasswordService.openDialog();
  }
}
