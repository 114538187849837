import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '../../core.service';

@Component({
  selector: 'web-banners-footer',
  templateUrl: './banners-footer.component.html',
  styleUrls: ['./banners-footer.component.scss'],
})
export class BannersFooterComponent extends BaseComponent implements OnInit {
  @Input() banners;

  constructor(translate: TranslateService, public coreService: CoreService) {
    super(translate);
  }

  ngOnInit() {
    // this.subscriptions.push(this.coreService.bannersFooter$.subscribe(res => {
    //   if (res) { this.banners = res; }
    // }));
  }
}
