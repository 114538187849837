import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  OnInit,
  ViewChild,
  forwardRef,
  Input,
} from "@angular/core";
import {
  FormGroup,
  Validators,
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
} from "@angular/forms";
import { IBAN } from "./iban.model";
import { getIbanLength, checkCC, checkForm } from "../../utils";
import { Observable } from "rxjs";
import { BaseComponent } from "../base/base.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "web-base-iban-input",
  templateUrl: "./iban-input.component.html",
  styleUrls: ["./iban-input.component.scss"],
  // providers: [{ provide: MatFormFieldControl, useExisting: IbanInputComponent }],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IbanInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IbanInputComponent),
      multi: true,
    },
  ],
})
export class IbanInputComponent
  extends BaseComponent
  implements OnInit, ControlValueAccessor {
  @Input() secrect: boolean = false;
  @Input() check: Observable<void>;
  @Input() required: boolean = true;
  @Input() placeholder: string = "IBAN";

  form: FormGroup;
  ibanLength = 4; // Longitud máxima del IBAN, en el caso de que no sea español.

  // public quantity: any;
  @ViewChild("iban", { static: true }) iban: ElementRef;
  public field1: ElementRef;
  public field2: ElementRef;
  public field3: ElementRef;
  public field4: ElementRef;
  public field5: ElementRef;

  @ViewChild("field1", { static: false }) set field1Set(field1: ElementRef) {
    this.field1 = field1;
  }
  @ViewChild("field2", { static: false }) set field2Set(field2: ElementRef) {
    this.field2 = field2;
  }
  @ViewChild("field3", { static: false }) set field3Set(field3: ElementRef) {
    this.field3 = field3;
  }
  @ViewChild("field4", { static: false }) set field4Set(field4: ElementRef) {
    this.field4 = field4;
  }
  @ViewChild("field5", { static: false }) set field5Set(field5: ElementRef) {
    this.field5 = field5;
  }

  // @Output()
  // changed: EventEmitter<{ value: string, error: boolean }> = new EventEmitter();

  @Output()
  finished: EventEmitter<string> = new EventEmitter();

  onChange = (_: any) => { };
  onTouch = () => { };

  // get errorState() {
  //   return this.form.errors !== null && !!this.form.touched;
  // }

  constructor(public translate: TranslateService, public elRef: ElementRef) {
    super(translate);
  }

  ngOnInit() {
    this.form = new FormGroup(
      {
        iban: new FormControl(""),
        field1: new FormControl(""),
        field2: new FormControl(""),
        field3: new FormControl(""),
        field4: new FormControl(""),
        field5: new FormControl(""),
      },
      this.validate.bind(this)
    );

    if (this.required) {
      this.form.controls["iban"].setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]);
      this.form.controls["field1"].setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]);
      this.form.controls["field2"].setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]);
      this.form.controls["field3"].setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]);
      this.form.controls["field4"].setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]);
      this.form.controls["field5"].setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]);
    }

    if (this.check) {
      this.subscriptions.push(
        this.check.subscribe(() => {
          this.setValidators();
          checkForm(this.form);
        })
      );
    }

    this.form.valueChanges.subscribe((val) => {
      // this.changeAccount.emit({ value: new IBAN(val).toString(), error: !this.form.valid });
      this.onChange(new IBAN(val).toString());
    });

    this.form.controls["iban"].valueChanges
      .pipe
      // tap((val: string) => {
      // this.form.controls['iban'].setValue(val.toUpperCase(), { emitEvent: false });
      // })
      ()
      .subscribe((val) => {
        this.form.controls["iban"].setValue(val.toUpperCase(), {
          emitEvent: false,
        });
        val = val.toUpperCase();

        this.ibanLength = getIbanLength(val.substr(0, 2));

        this.setValidators(val);

        if (val.length === 4 && val.substr(0, 2) === "ES" && this.field1) {
          this.field1.nativeElement.focus();
        }
      });

    for (let i = 1; i < 6; i++) {
      this.form.controls["field" + i].valueChanges.subscribe((val) => {
        this.focusNextField(val, i);
      });
    }
  }

  setValidators(iban?) {
    iban = iban !== undefined ? iban : new IBAN(this.form.value).toString();

    if (this.required || iban) {
      this.form.controls["iban"].setValidators([
        Validators.required,
        Validators.minLength(this.ibanLength),
        Validators.maxLength(this.ibanLength),
      ]);
    } else if (!this.required) {
      this.form.controls["iban"].clearValidators();
    }

    this.form.controls["iban"].updateValueAndValidity({ emitEvent: false });

    for (let i = 1; i < 6; i++) {
      if (iban.substr(0, 2) === "ES" && iban.length === 4) {
        this.form.controls["field" + i].setValidators([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
        ]);
      } else {
        this.form.controls["field" + i].clearValidators();
      }

      this.form.controls["field" + i].markAsTouched();
      this.form.controls["field" + i].updateValueAndValidity({
        emitEvent: false,
      });
    }
  }

  validate(g: FormGroup) {
    const iban = new IBAN(g.value);

    if (!this.required && iban && !iban.toString()) {
      return null;
    } else if (
      iban &&
      iban.toString() &&
      iban.toString().substr(0, 2) ===
      "ES" /* && iban.toString().length === 24 */
    ) {
      const checkCCResult = checkCC(iban);
      if (checkCCResult !== false) {
        this.finished.emit(iban.toString());

        return null;
      } else {
        return {
          incorrectIBAN: true,
        };
      }
    } else if (
      iban &&
      iban.toString() &&
      !/^[A-Z]{2}/.test(iban.toString().substr(0, 2))
    ) {
      return {
        incorrectIBAN: true,
      };
    } else if (
      g &&
      !g.controls &&
      g.value &&
      (g.value.length < this.ibanLength || g.value.length > this.ibanLength)
    ) {
      return {
        incorrectIBAN: true,
      };
    }

    this.finished.emit(iban.toString());

    return null;
  }

  focusNextField(val: string, number) {
    if (val.length === 4) {
      const nextField = "field" + (number + 1);

      if (this[nextField] && this[nextField].nativeElement) {
        this[nextField].nativeElement.focus();
      }
    } else if (val.length === 0) {
      const nextField = number === 1 ? "iban" : "field" + (number - 1);
      this.secrect = false;

      if (this[nextField] && this[nextField].nativeElement) {
        this[nextField].nativeElement.focus();
      }
    }
  }

  writeValue(value: any): void {
    if (value) {
      value = new IBAN(value);
      this.form.setValue(value);
      // this.stateChanges.next();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== "input") {
      this.elRef.nativeElement.querySelector("input").focus();
    }
  }

  onPaste(event) {
    let clipboardContent = event.clipboardData.getData("text/plain");
    clipboardContent = clipboardContent.replace(/[- .]/g, "");

    if (clipboardContent.substr(0, 2) === "ES") {
      if (clipboardContent.length === 20) {
        const val1 = clipboardContent.substr(0, 4);
        const val2 = clipboardContent.substr(4, 4);
        const val3 = clipboardContent.substr(8, 4);
        const val4 = clipboardContent.substr(12, 4);
        const val5 = clipboardContent.substr(16, 4);
        this.form.patchValue({
          field1: val1,
          field2: val2,
          field3: val3,
          field4: val4,
          field5: val5,
        });
      } else if (clipboardContent.length === 24) {
        const val0 = clipboardContent.substr(0, 4);
        const val1 = clipboardContent.substr(4, 4);
        const val2 = clipboardContent.substr(8, 4);
        const val3 = clipboardContent.substr(12, 4);
        const val4 = clipboardContent.substr(16, 4);
        const val5 = clipboardContent.substr(20, 4);
        this.form.patchValue({
          iban: val0,
          field1: val1,
          field2: val2,
          field3: val3,
          field4: val4,
          field5: val5,
        });
      }
    } else {
      this.form.controls["iban"].setValue(clipboardContent);
    }
  }
}
