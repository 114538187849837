export const environment = {
  production: true,
  ssrHost: "https://new-enfermeriagranada-web.vfges.com",
  isDev: true,
};

export const RECAPTCHA_KEY = "6Lf06cIUAAAAAHX4lksKhc7q-orXpQRWPKPchdOG";

// KEY de la web anterior, deja de funcionar cuando supera la cuota, que es muy baja.
// export const YOUTUBE_KEY = 'AIzaSyB-_93Rv37sRaS6GwAzUfR_ug0zHrFGmow';
// KEY PROVISIONAL creada desde la cuenta eduardo@viafisio.com para el proyecto "Enfermeria Granada".
export const YOUTUBE_KEY = "AIzaSyBnAesYF2cyqzgkg72QZm39BUIXFkxBy04";

export const URL = "https://new-api-enfermeriagranada.vfges.com/web";
export const URL_WEB = "https://new-enfermeriagranada-web.vfges.com";
export const publicS3Url =
  "https://s3-eu-west-1.amazonaws.com/new-public.enfermeriagranada.vfges.com/";

export const REDSYS_URL = "https://sis-t.redsys.es:25443/sis/realizarPago"; // XXXX = 25443
export const REDSYS_VERSION = "HMAC_SHA256_V1";
