import { Injectable, Inject } from '@angular/core';
import { URL } from '../../../environments/environment';

import { CoreService } from '../core/core.service';
import { HttpClient } from '@angular/common/http';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  errorLogin: string;
  public user: any;

  public loggedIn = new BehaviorSubject<boolean>(true);
  public loggedIn$ = this.loggedIn.asObservable();

  constructor(
    // public router: Router,
    // public route: ActivatedRoute,
    public coreService: CoreService,
    public http: HttpClient,
    public router: Router
  ) {
    this.coreService.templateLoaded$
      /*
      .pipe(
        take(1)
      )
      */
      .subscribe((data) => {
        if (data) {
          this.user = data.result.user;

          if (this.user) {
            if (!this.user.conditionsAccepted) {
              if (
                this.user.elementTypeID ===
                ConstantsProject.ELEMENT_TYPE_COLLEGIATE
              ) {
                this.router.navigate(['mi-zona-privada', 'primer-acceso']);
              } else if (
                this.user.elementTypeID ===
                ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE
              ) {
                this.router.navigate([
                  'mi-zona-privada-precolegial',
                  'primer-acceso',
                ]);
              }
            }

            this.loggedIn.next(true);
          } else {
            localStorage.removeItem(ConstantsProject.TOKEN_KEY);
            this.loggedIn.next(false);
          }
        }
      });
  }

  // isLoggedIn(): boolean {
  isLoggedIn(): Observable<boolean> {
    // if (isPlatformBrowser(this.platformId)) {
    //   if (localStorage.getItem(ConstantsProject.TOKEN_KEY)) {
    //     return true;
    //   }
    //   return false;
    // }
    // return false;
    return this.loggedIn$;
  }

  logout() {
    localStorage.removeItem(ConstantsProject.TOKEN_KEY);
    this.coreService.loadTemplate();
  }

  rememberPass(data: { type: number; number: string }) {
    let prefix;
    switch (data.type) {
      case ConstantsProject.ELEMENT_TYPE_COLLEGIATE:
        prefix = 'collegiates';
        break;
      case ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE:
        prefix = 'precollegiates';
        break;
      default:
        break;
    }

    const url = `${URL}/${prefix}/remember-password`;

    return this.http.post(url, { number: data.number });
  }
}
