import { NgModule } from "@angular/core";

import * as AuthModuleBase from "../../base/auth/auth.module";
import { SigninComponent } from "./signin/signin.component";
import { AuthRoutingModule } from "./auth-routing.module";

@NgModule({
  imports: [AuthRoutingModule, AuthModuleBase.AuthModule],
  exports: [],
  declarations: [SigninComponent],
  entryComponents: [],
  providers: [],
})
export class AuthModule { }
