import { SharedService } from '@web/project/shared/shared.service';
import { BaseWebComponent } from '@web/project/shared/components/base-web/base-web.component';
import { CookiesService } from './cookies.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-project-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent extends BaseWebComponent implements OnInit {
  section = 'cookies';
  showSidenav = false;

  GOOGLE_ANALYTICS4_ID = ConstantsProject.GOOGLE_ANALYTICS4_ID;
  COOKIES_PREFIX = ConstantsProject.COOKIES_PREFIX;
  COOKIES_CONSENT = ConstantsProject.COOKIES_CONSENT;

  breadcrumbs = [{ label: '', url: '', navigable: false }];

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public cookiesService: CookiesService
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.getTranslations(['core', 'about']).then(() => {
      this.breadcrumbs[0].label = this.translations['core']['cookiesPolicy'];
      this.setBreadcrumbs();
    });
  }

  showCookiesConsent() {
    this.cookiesService.showCookiesConsent.next(true);
  }
}
