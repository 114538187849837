import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "web-base-simple-dialog",
  templateUrl: "./simple-dialog.component.html",
  styleUrls: ["./simple-dialog.component.scss"],
})
export class SimpleDialogComponent implements OnInit {
  static TYPE_DEFAULT = 0;
  static TYPE_INFO = 1;
  static TYPE_OK = 2;
  static TYPE_ERROR = 3;
  static TYPE_CONFIRM = 4;
  static TYPE_LOADING = 5;
  static TYPE_YES_NO = 6;

  public DIALOG_TYPE: any = {
    DEFAULT: SimpleDialogComponent.TYPE_DEFAULT,
    INFO: SimpleDialogComponent.TYPE_INFO,
    OK: SimpleDialogComponent.TYPE_OK,
    ERROR: SimpleDialogComponent.TYPE_ERROR,
    CONFIRM: SimpleDialogComponent.TYPE_CONFIRM,
    LOADING: SimpleDialogComponent.TYPE_LOADING,
    YES_NO: SimpleDialogComponent.TYPE_YES_NO,
  };

  confirmText = "Aceptar";
  rejectText = "No";
  cancelText = "Cancelar";
  okText = "OK, continuar";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SimpleDialogComponent>
  ) {
    if (!data.type) {
      data.type = SimpleDialogComponent.TYPE_INFO;
    }

    if (data.confirmText) {
      this.confirmText = data.confirmText;
    }

    if (data.rejectText) {
      this.rejectText = data.rejectText;
    }

    if (data.cancelText) {
      this.cancelText = data.cancelText;
    }
  }

  ngOnInit() { }

  close(val?) {
    this.dialogRef.close(val);
  }
}
