import { EmbedVideoService as EmbedVideoServiceBase } from '@web/base/shared/components/videos-module/embed-video-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class EmbedVideoService extends EmbedVideoServiceBase {
  constructor(public http: HttpClient) {
    super(http);
  }
}
