import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService as SharedServiceBase } from '@web/base/shared/shared.service';

import { URL as URL_API } from '../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { CoreService } from '@web/base/core/core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsProject } from './constants.class';

@Injectable()
export class SharedService extends SharedServiceBase {
  public openSigninDialog = new Subject();
  public openSigninDialog$ = this.openSigninDialog.asObservable();

  private searchActive$ = new Subject();

  constructor(
    public http: HttpClient,
    private coreService: CoreService,
    private router: Router,
    public route: ActivatedRoute
  ) {
    super(http);
  }

  getSearchActive() {
    return this.searchActive$;
  }

  setSearchActive(active) {
    this.searchActive$.next(active);
  }

  loadProvinces() {
    const url = `${URL_API}/provinces/find`;
    return this.http.post(url, {});
  }

  loadTitulations() {
    const url = `${URL_API}/titulations/find`;
    return this.http.post(url, {});
  }

  loadSpecialties() {
    const url = `${URL_API}/specialties/find`;
    return this.http.post(url, {});
  }

  loadSpecialtyAccessRoads() {
    const url = `${URL_API}/specialty-access-roads/find`;
    return this.http.post(url, {});
  }

  loadIdioms() {
    const url = `${URL_API}/idioms/find`;
    return this.http.post(url, {});
  }

  loadIdiomLevels() {
    const url = `${URL_API}/idiom-levels/find`;
    return this.http.post(url, {});
  }

  existsNoActiveDocumentNumber(documentNumber: string) {
    const url = `${URL_API}/collegiates/exists-no-active`;
    return this.http.post(url, { documentNumber });
  }

  loadProfessionalsCategories() {
    const url = `${URL_API}/professionals-categories/find`;
    return this.http.post(url, {});
  }

  getCollegiateCard() {
    const url = `${URL_API}/collegiates/card`;
    return this.http.post(url, {});
  }

  /**
   * Función para comprobar si se puede acceder a una sección.
   * Si el usuario no está logueado, directamente va al login.
   * El parámetro collegiate indica si el usuario logueado es colegiado.
   * El parámetro retired indica si el usuario logueado es jubilado.
   * El parámetro voluntary indica si el usuario logueado es baja no ejercer.
   * para acceder a la sección.
   * @param collegiate
   * @param retired
   * @param voluntary
   */
  checkPrivateAccess(collegiate, retired, voluntary) {
    return new Promise<void>((resolve, reject) => {
      let isPrivate;
      const typeUser = [];

      if (collegiate || retired || voluntary) {
        this.coreService.templateLoaded$.subscribe(
          (t) => {
            if (t) {
              // Si la sección es privada y el usuario no está logueado, redirigimos al login.
              if (
                (!collegiate && !retired && !voluntary) ||
                (t.result.user &&
                  ((collegiate &&
                    t.result.user.elementTypeID ===
                    ConstantsProject.ELEMENT_TYPE_COLLEGIATE) ||
                    (retired &&
                      t.result.user.elementTypeID ===
                      ConstantsProject.ELEMENT_TYPE_RETIRED) ||
                    (voluntary &&
                      t.result.user.elementTypeID ===
                      ConstantsProject.ELEMENT_TYPE_VOLUNTARY)))
              ) {
                isPrivate = false;
              } else {
                isPrivate = true;
              }

              // Comprobamos los permisos de la sección y enviamos los tipos
              if (
                collegiate &&
                !typeUser.includes(ConstantsProject.ELEMENT_TYPE_COLLEGIATE)
              ) {
                typeUser.push(ConstantsProject.ELEMENT_TYPE_COLLEGIATE);
              }
              if (
                retired &&
                !typeUser.includes(ConstantsProject.ELEMENT_TYPE_RETIRED)
              ) {
                typeUser.push(ConstantsProject.ELEMENT_TYPE_RETIRED);
              }
              if (
                voluntary &&
                !typeUser.includes(ConstantsProject.ELEMENT_TYPE_VOLUNTARY)
              ) {
                typeUser.push(ConstantsProject.ELEMENT_TYPE_VOLUNTARY);
              }

              // Si tiene algún check de privado activo y no esta logueado le llevamos al login pasando los tipos
              if (isPrivate) {
                if (!this.router.url.startsWith('login')) {
                  this.router.navigate(['login'], {
                    queryParams: { returnUrl: this.router.url, type: typeUser },
                  });
                }
              }

              resolve();
            }
          },
          (err) => reject(err)
        );
      } else {
        resolve();
      }
    });
  }
}
