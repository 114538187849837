import { Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { BaseListWebService } from '@web/base/shared/components/base-list-web/base-list-web.service';
import { BaseListWebComponent } from '@web/project/shared/components/base-list-web/base-list-web.component';
import { IBaseListWebItem } from '@web/project/shared/models/base-list-web-item.model';

@Component({
  selector: 'web-courses-content',
  templateUrl: './courses-content.component.html',
  styleUrls: ['./courses-content.component.scss'],
  providers: [BaseListWebService],
})
export class CoursesContentComponent extends BaseListWebComponent {
  @Input() content$: Observable<Array<IBaseListWebItem>>;
  public items;

  getData() {
    this.subscriptions.push(
      this.content$.subscribe((content) => {
        this.items$.next(
          content.map((c) => {
            const image = c.files.find((f) => f.name === 'image');
            c.image = image && image.url ? image.url : null;
            return c;
          })
        );

        this.items = [...content];
      })
    );
  }

}
