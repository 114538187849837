import { PipeTransform, Pipe, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TrueFalsePipe as TrueFalsePipeBase } from '@web/base/shared/pipes/trueFalse.pipe';

@Pipe({ name: 'trueFalse' })
@Injectable()
export class TrueFalsePipe extends TrueFalsePipeBase implements PipeTransform {
  constructor(public translate: TranslateService) {
    super(translate);
  }
}
