import { SimpleSnackbarComponent as SimpleSnackbarComponentBase } from '@web/base/shared/components/simple-snackbar/simple-snackbar.component';
import { Component } from '@angular/core';

@Component({
  selector: 'web-simple-snackbar',
  templateUrl:
    './../../../../base/shared/components/simple-snackbar/simple-snackbar.component.html',
  styleUrls: [
    './../../../../base/shared/components/simple-snackbar/simple-snackbar.component.scss',
  ],
})
export class SimpleSnackbarComponent extends SimpleSnackbarComponentBase { }
