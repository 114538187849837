import { VideoDialogComponent as VideoDialogComponentBase } from '@web/base/shared/components/videos-module/video-dialog/video-dialog.component';
import {
  Component,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { getCookie } from '@web/project/shared/utils';

@Component({
  selector: 'web-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent
  extends VideoDialogComponentBase
  implements AfterViewInit {
  cookie = null;
  COOKIES_CONSENT = ConstantsProject.COOKIES_CONSENT;
  showVideo = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    private cdRef: ChangeDetectorRef
  ) {
    super(data, dialogRef);
  }

  ngAfterViewInit() {
    this.cookie = JSON.parse(getCookie(this.COOKIES_CONSENT));
    if (this.cookie && this.cookie.functionals) {
      this.showVideo = true;
      this.cdRef.detectChanges();
    }
  }
}
