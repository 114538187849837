import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from "@angular/core";
import { PaginationControlsComponent } from "ngx-pagination";

@Component({
  selector: "web-base-paginate",
  templateUrl: "./paginate.component.html",
  styleUrls: ["./paginate.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PaginateComponent extends PaginationControlsComponent { }
