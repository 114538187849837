import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthCollegiateGuard as AuthCollegiateGuardBase } from '@web/base/auth/auth-collegiate.guard';

@Injectable()
export class AuthCollegiateGuard extends AuthCollegiateGuardBase {
  constructor(public authService: AuthService, public router: Router) {
    super(authService, router);
  }
}
