import { ImagesFadingComponent } from './components/images-fading/images-fading.component';
import { BaseWebComponent } from './components/base-web/base-web.component';
import { NgModule, ModuleWithProviders } from '@angular/core';

// import { SharedService } from './shared.service';
import { BaseListWebComponent } from './components/base-list-web/base-list-web.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { ImagesModuleComponent } from './components/images-module/images-module.component';
import { ImageGalleryComponent } from './components/images-module/image-gallery/image-gallery.component';
import { AttachmentsModuleComponent } from './components/attachments-module/attachments-module.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { VideosModuleComponent } from './components/videos-module/videos-module.component';
import { VideoDialogComponent } from './components/videos-module/video-dialog/video-dialog.component';

import { CalendarComponent } from './components/calendar/calendar.component';
import { AttachmentsHandleComponent } from './components/attachments-handle/attachments-handle.component';
import { EditAttachmentComponent } from './components/attachments-handle/dialogs/edit-attachment/edit-attachment.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
// import { ImageGalleryService } from './components/images-module/image-gallery/service/image-gallery.service';
// import { EmbedVideoService } from './components/videos-module/embed-video-service';
// import { AuthGuard } from '../auth/auth.guard';
// import { AuthCollegiateGuard } from '../auth/auth-collegiate.guard';
// import { AuthPrecollegiateGuard } from '../auth/auth-precollegiate.guard';
import { AttachmentComponent } from './components/attachment/attachment.component';

import { SharedModule as SharedModuleProject } from './../../project/shared/shared.module';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { SharedService } from '@web/project/shared/shared.service';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { GetInitialsPipe } from './pipes/initials.pipe';
import { YesNoPipe } from './pipes/yesNo.pipe';
import { TrueFalsePipe } from './pipes/trueFalse.pipe';
import { PaymentTpvComponent } from './components/payment-tpv/payment-tpv.component';
import { TinymceInputComponent } from './components/tinymce-input/tinymce-input.component';
import { ChipsAutocompleteInputComponent } from './components/chips-autocomplete-input/chips-autocomplete-input.component';
import { FormTranslationsComponent } from './components/form-translations/form-translations.component';
import { ElementSearchInputComponent } from './components/element-search-input/element-search-input.component';
import { TableComponent } from './components/table/table.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { SearchElementComponent } from './components/element-search-input/dialogs/search-element/search-element.component';
import { DefaultImageDirective } from './directives/default-image.directive';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { SimpleSnackbarComponent } from './components/simple-snackbar/simple-snackbar.component';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { IbanInputComponent } from './components/iban-input/iban-input.component';
import { BaseComponent } from './components/base/base.component';
import { MoveNextOnCompleteDirective } from './directives/move-next-on-complete.directive';
import { PaginateComponent } from './components/paginate/paginate.component';

@NgModule({
  imports: [SharedModuleProject],
  declarations: [
    BaseComponent,
    IbanInputComponent,
    SimpleDialogComponent,
    SimpleSnackbarComponent,
    TimeInputComponent,
    BaseWebComponent,
    BaseListWebComponent,
    AttachmentsComponent,
    ImagesModuleComponent,
    ImageGalleryComponent,
    AttachmentsModuleComponent,
    VideosModuleComponent,
    VideoDialogComponent,
    FileInputComponent,
    CalendarComponent,
    AttachmentsHandleComponent,
    EditAttachmentComponent,
    LoadingButtonComponent,
    AttachmentComponent,
    ImagesFadingComponent,
    SectionTitleComponent,
    PaginateComponent,
    PaymentTpvComponent,
    TinymceInputComponent,
    ChipsAutocompleteInputComponent,
    FormTranslationsComponent,
    TableComponent,
    NoResultsComponent,
    ElementSearchInputComponent,
    SearchElementComponent,
    DefaultImageDirective,
    MoveNextOnCompleteDirective,
    SafeHtmlPipe,
    FileSizePipe,
    TruncatePipe,
    SlugifyPipe,
    GetInitialsPipe,
    YesNoPipe,
    TrueFalsePipe,
  ],
  exports: [SharedModuleProject, DefaultImageDirective],
  entryComponents: [
    // ImageGalleryComponent,
    // VideoDialogComponent,
    // EditAttachmentComponent,
  ],
  providers: [
    // ImageGalleryService,
    // EmbedVideoService,
    // AuthGuard,
    // AuthCollegiateGuard,
    // AuthPrecollegiateGuard,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [SharedService],
    };
  }

  static forChild(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
    };
  }
}
