import { PopupDialogComponent as PopupDialogComponentBase } from '@web/base/core/popup-dialog/popup-dialog.component';
import { Component, OnInit } from '@angular/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { setCookie } from '@web/project/shared/utils';

@Component({
  selector: 'web-project-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./../../../base/core/popup-dialog/popup-dialog.component.scss'],
})
export class PopupDialogComponent
  extends PopupDialogComponentBase
  implements OnInit {
  // public functionalCookiesConsent = false;
  public necessaryCookiesConsent = true; // Vamos a considerar las cookies de las ventanas emergentes como necesarias

  ngOnInit() {
    super.ngOnInit();

    // Comprobar cookies para mostrar los botones de ocultacion o no
    // const cookiesConsent = JSON.parse(getCookie(ConstantsProject.COOKIES_CONSENT));
    // this.functionalCookiesConsent = (!cookiesConsent || (cookiesConsent && !cookiesConsent.functionals)) ? false : true;
  }

  hide7days() {
    if (this.necessaryCookiesConsent) {
      setCookie(
        ConstantsProject.COOKIES_PREFIX + 'popup_' + this.data.popup.popupID,
        this.data.popup.popupID,
        7
      );
    }
    this.dialogRef.close();
  }

  hide4ever() {
    if (this.necessaryCookiesConsent) {
      setCookie(
        ConstantsProject.COOKIES_PREFIX + 'popup_' + this.data.popup.popupID,
        this.data.popup.popupID,
        99999
      );
    }
    this.dialogRef.close();
  }
}
