import { ConstantsProject } from '@web/project/shared/constants.class';
import { Component } from '@angular/core';
import { FooterComponent as FooterComponentBase } from '@web/base/core/footer/footer.component';

@Component({
  selector: 'web-project-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends FooterComponentBase {
  public OFFICE_ADDRESSES = ConstantsProject.OFFICE_ADDRESSES;
}
