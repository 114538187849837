import { Injectable, TemplateRef } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SearchElementComponent } from './search-element/search-element.component';
import { ITableEstructure } from '@web/project/shared/models/table-structure.model';
import { FormGroup } from '@angular/forms';

@Injectable()
export class ElementSearchDialogService {
  constructor(public dialog: MatDialog) { }

  openDialog(
    structure: Array<ITableEstructure>,
    loadFunction: string,
    sectionTitle: string,
    formFilter?: FormGroup,
    formTemplate?: TemplateRef<any>,
    canCreate?: boolean,
    extraFilter?,
    dialogConfig?
  ): Observable<any | boolean> {
    let dialogRef: MatDialogRef<SearchElementComponent>;
    const config = new MatDialogConfig();

    if (dialogConfig) {
      Object.keys(dialogConfig).forEach((k) => {
        config[k] = dialogConfig[k];
        config[k] = dialogConfig[k];
        config[k] = dialogConfig[k];
      });
    } else {
      config.disableClose = true;
      config.width = '95%';
      config.maxWidth = '1400px';
    }

    dialogRef = this.dialog.open(SearchElementComponent, config);

    dialogRef.componentInstance.structure = structure;
    dialogRef.componentInstance.loadFunction = loadFunction;
    dialogRef.componentInstance.sectionTitle = sectionTitle;
    dialogRef.componentInstance.extraFilter = extraFilter;
    dialogRef.componentInstance.canCreate = canCreate;
    dialogRef.componentInstance.formFilter = formFilter;
    dialogRef.componentInstance.formTemplate = formTemplate;

    return dialogRef.afterClosed();
  }
}
