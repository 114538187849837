import * as AuthActions from "./auth.actions";

export interface State {
  token: string;
}

const initialState: State = {
  token: null,
};

export function authReducer(
  state = initialState,
  action: AuthActions.AuthActions
) {
  switch (action.type) {
    case AuthActions.SIGNIN:
      return {
        ...state,
      };
    case AuthActions.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
}
