import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedVideoService } from './embed-video-service';
import { order } from '@web/base/shared/utils';
import { IVideo } from '@web/project/shared/models/video.model';
import { VideoDialogComponent } from '@web/project/shared/components/videos-module/video-dialog/video-dialog.component';
import { MatGridList } from '@angular/material/grid-list';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'web-base-videos-module',
  templateUrl: './videos-module.component.html',
  styleUrls: ['./videos-module.component.scss'],
})
export class VideosModuleComponent implements OnInit, AfterViewInit {
  @ViewChild('videoGallery', { static: false }) videoGallery: ElementRef;
  @ViewChild('grid', { static: false }) grid: MatGridList;
  public galleryColumns: number;
  public innerWidth: number;

  @Input() videos: IVideo[];
  @Input() title: string;
  @Input() order: 'asc' | 'desc' = 'asc';
  @Input() showTitle: boolean;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.recalculateGrid();
  }

  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public embedVideoService: EmbedVideoService
  ) { }

  ngOnInit() {
    if (this.order === 'asc') {
      this.videos.sort((a, b) => order(a, b));
    } else {
      this.videos.sort((a, b) => order(a, b, 'order', 'desc'));
    }

    this.videos.map(async (v, i) => {
      this.embedVideoService.url_image(v.videoUrl).then((thumbnail) => {
        this.videos[i].thumbnail = thumbnail;
      });
    });
  }

  ngAfterViewInit() {
    this.recalculateGrid();
  }

  recalculateGrid() {
    this.innerWidth = +(<ElementRef>(
      this.videoGallery.nativeElement.clientWidth
    ));
    if (this.innerWidth < 300) {
      this.galleryColumns = 2;
    } else if (this.innerWidth >= 300 && this.innerWidth < 500) {
      this.galleryColumns = 4;
    } else {
      this.galleryColumns = 8;
    }

    this.grid.cols = this.galleryColumns;
  }

  loadVideo(video: IVideo) {
    this.dialog.open(VideoDialogComponent, {
      panelClass: ['dialog', 'dialog-sm', 'video-dialog'],
      data: {
        title: video.title,
        video: this.sanitizer.bypassSecurityTrustResourceUrl(
          this.embedVideoService.embed_video(video.videoUrl)
        ),
      },
      width: '75%',
    });
  }
}
