import { Action } from "@ngrx/store";

import { UserLogin } from "./../user-login.model";

export const TRY_SIGNIN = "TRY_SIGNIN";
export const TRY_SIGNIN_BY_CODE_AND_HASH = "TRY_SIGNIN_BY_CODE_AND_HASH";
export const SIGNIN = "SIGNIN";
export const SET_TOKEN = "SET_TOKEN";

export class TrySignin implements Action {
  readonly type = TRY_SIGNIN;

  constructor(public payload: UserLogin) { }
}

export class TrySigninByCodeAndHash implements Action {
  readonly type = TRY_SIGNIN_BY_CODE_AND_HASH;

  constructor(public code: string, public hash: string) { }
}

export class Signin implements Action {
  readonly type = SIGNIN;
}

export class SetToken implements Action {
  readonly type = SET_TOKEN;

  constructor(public payload: string) { }
}

export type AuthActions =
  | TrySignin
  | TrySigninByCodeAndHash
  | Signin
  | SetToken;
