import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { GetInitialsPipe as GetInitialsPipeBase } from '@web/base/shared/pipes/initials.pipe';

@Pipe({
  name: 'getInitials',
})
@Injectable()
export class GetInitialsPipe
  extends GetInitialsPipeBase
  implements PipeTransform { }
