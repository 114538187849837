import { SimpleDialogComponent as SimpleDialogComponentBase } from '@web/base/shared/components/simple-dialog/simple-dialog.component';
import { Component } from '@angular/core';

@Component({
  selector: 'web-simple-dialog',
  templateUrl:
    './../../../../base/shared/components/simple-dialog/simple-dialog.component.html',
  styleUrls: [
    './../../../../base/shared/components/simple-dialog/simple-dialog.component.scss',
  ],
})
export class SimpleDialogComponent extends SimpleDialogComponentBase { }
