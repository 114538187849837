import { Component } from '@angular/core';
import { BaseListWebService } from '@web/project/shared/components/base-list-web/base-list-web.service';
import { ListContentComponent as ListContentComponentBase } from '@web/base/core/home/list-content/list-content.component';

@Component({
  selector: 'web-project-home-list-content',
  templateUrl:
    './../../../shared/components/base-list-web/base-list-web.component.html',
  styleUrls: [
    './../../../shared/components/base-list-web/base-list-web.component.scss',
  ],
  providers: [BaseListWebService],
})
export class ListContentComponent extends ListContentComponentBase {
  showCategory = false;
}
