import { Component, Input, Inject, HostListener, EventEmitter, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent, group, query } from '@angular/animations';

import { ImageGalleryOverlayRef } from './image-gallery-overlay-ref';
import { GALLERY_DATA, IMAGE_DATA, IMAGE_INDEX, GALLERY_COUNTER, IMAGE_ACTION_BUTTON } from './service/image-gallery-overlay.tokens';
import { IImageGallery } from '@web/project/shared/models/image-gallery.model';

const ESCAPE = 27;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'web-base-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate(ANIMATION_TIMINGS))
    ]),
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ]),
    trigger(
      'interface', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        style({ opacity: 1 }),
        animate('50ms', style({ opacity: 0 }))
      ])
    ]
    )
  ]
})
export class MultimediaImageGalleryComponent implements OnInit {

  public loading = true;
  public animationState: 'void' | 'enter' | 'leave' = 'enter';
  public animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();
  public showInterface = false;
  public hasNavigation = false;
  public actionButtonEmitter: EventEmitter<{ callBackFunction: string, image: IImageGallery }>
    = new EventEmitter<{ callBackFunction: string, image: IImageGallery }>();

  @HostListener('document:keydown', ['$event']) public handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE) {
      this.dialogRef.close();
    }
    if (event.keyCode === LEFT_ARROW) {
      this.previousImage();
    }
    if (event.keyCode === RIGHT_ARROW) {
      this.nextImage();
    }
  }

  constructor(
    public dialogRef: ImageGalleryOverlayRef,
    @Inject(IMAGE_DATA) public image: IImageGallery,
    @Inject(GALLERY_DATA) public gallery: IImageGallery[],
    @Inject(IMAGE_INDEX) public imageIndex: number,
    @Inject(GALLERY_COUNTER) public counter: boolean,
    @Inject(IMAGE_ACTION_BUTTON) public actionButton: { title: string, color: string, callBackFunction: string }) { }

  ngOnInit() {
    this.hasNavigation = this.gallery != null;
  }

  onLoadImage(event: Event) {
    this.loading = false;
  }

  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
    this.showInterface = true;
  }

  startExitAnimation() {
    this.animationState = 'leave';
  }

  close() {
    this.dialogRef.close();
  }

  onClickImage() {
    // if (this.image.url) {
    //   const newWindow = window.open(this.image.url);
    // }
  }

  previousImage() {
    if (this.hasNavigation) {
      const previousImg = this.image.url;

      this.loading = true;
      this.imageIndex = this.imageIndex === 0 ? this.gallery.length - 1 : this.imageIndex - 1;
      this.image = this.gallery[this.imageIndex];

      if (this.image.url === previousImg) {
        this.loading = false;
      }
    }
  }

  nextImage() {
    if (this.hasNavigation) {
      const previousImg = this.image.url;

      this.loading = true;
      this.imageIndex = this.imageIndex === this.gallery.length - 1 ? 0 : this.imageIndex + 1;
      this.image = this.gallery[this.imageIndex];

      if (this.image.url === previousImg) {
        this.loading = false;
      }
    }
  }

  onClickActionButton() {
    this.actionButtonEmitter.emit({ callBackFunction: this.actionButton.callBackFunction, image: this.image });
    this.close();
  }
}
