import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { getCookie, setCookie } from '../../shared/utils';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent extends BaseComponent implements OnInit {
  @Output() openCookiesPolicy = new EventEmitter();
  @Output() acceptCookies = new EventEmitter();

  constructor(public translate: TranslateService) {
    super(translate);
  }

  ngOnInit() { }

  showInfo() {
    this.openCookiesPolicy.emit();
  }

  accept() {
    setCookie(ConstantsProject.COOKIES_PREFIX + 'aceptar_cookies', 1, 99999);
    this.acceptCookies.emit();
  }
}
