import { CoreService } from '@web/base/core/core.service';
import { BaseWebComponent } from '@web/project/shared/components/base-web/base-web.component';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@web/project/shared/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IResponse } from '@web/project/shared/models/response.model';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Subject } from 'rxjs';
import { IBaseListWebItem } from '@web/project/shared/models/base-list-web-item.model';
import { order } from '@web/base/shared/utils';

@Component({
  selector: 'web-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseWebComponent implements OnInit {
  section = 'home';

  bannersHeader = [];

  totalSliderItems: number;
  totalGridItems: number;
  totalListItems: number;

  public sliderContent = new Subject<Array<IBaseListWebItem>>();
  public sliderContent$ = this.sliderContent.asObservable();

  public gridContent = new Subject<Array<IBaseListWebItem>>();
  public gridContent$ = this.gridContent.asObservable();

  public listContent = new Subject<Array<IBaseListWebItem>>();
  public listContent$ = this.listContent.asObservable();

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public coreService: CoreService
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.coreService.loadCover().subscribe((v: IResponse) => {
      // Banners

      this.bannersHeader = v.result.banners
        .filter((b) => b.bannerPositionID === ConstantsProject.BANNER_TOP)
        .sort((a, b) => order(a, b));

      // Elementos de la portada

      this.sliderContent.next(v.result.sliderContent);
      this.gridContent.next(v.result.gridContent);
      this.listContent.next(v.result.listContent);

      this.totalSliderItems = +v.result.sliderContent.length;
      this.totalGridItems = +v.result.gridContent.length;
      this.totalListItems = +v.result.listContent.length;
    });
  }
}
