import { SigninComponent } from "./signin.component";
import { Injectable } from "@angular/core";
import {
  MatDialogRef,
  MatDialogConfig,
  MatDialog,
} from "@angular/material/dialog";

@Injectable()
export class SigninService {
  dialogRef: MatDialogRef<SigninComponent>;

  constructor(public dialog: MatDialog) { }

  openDialog() {
    // let dialogRef: MatDialogRef<SigninComponent>;
    const config = new MatDialogConfig();
    this.dialogRef = this.dialog.open(SigninComponent, {
      panelClass: "dialog",
    });
    return this.dialogRef.afterClosed();
  }

  closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
