import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class HeaderService {
  headerHidden$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  getHeaderHidden() {
    return this.headerHidden$;
  }

  setHeaderHidden(hidden) {
    this.headerHidden$.next(hidden);
  }
}
