import { InjectionToken } from '@angular/core';
import { IImageGallery } from '@web/project/shared/models/image-gallery.model';
import { ImageActionButton } from '@web/project/shared/models/image-action-button.model';

export const GALLERY_DATA = new InjectionToken<IImageGallery[]>('GALLERY_DATA');
export const IMAGE_DATA = new InjectionToken<IImageGallery>('IMAGE_DATA');
export const IMAGE_INDEX = new InjectionToken<number>('IMAGE_INDEX');
export const GALLERY_COUNTER = new InjectionToken<boolean>('GALLERY_COUNTER');
export const IMAGE_ACTION_BUTTON = new InjectionToken<ImageActionButton>(
  'IMAGE_ACTION_BUTTON'
);
