import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { IbanInputComponent as IbanInputComponentBase } from '@web/base/shared/components/iban-input/iban-input.component';

@Component({
  selector: 'web-iban-input',
  templateUrl:
    './../../../../base/shared/components/iban-input/iban-input.component.html',
  styleUrls: [
    './../../../../base/shared/components/iban-input/iban-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IbanInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IbanInputComponent),
      multi: true,
    },
  ],
})
export class IbanInputComponent extends IbanInputComponentBase { }
