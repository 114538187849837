import { Component, ElementRef } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ElementSearchInputComponent as ElementSearchInputComponentBase } from '@web/base/shared/components/element-search-input/element-search-input.component';
import { ElementSearchDialogService } from './dialogs/element-search-dialog.service';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'web-element-search-input',
  templateUrl:
    './../../../../base/shared/components/element-search-input/element-search-input.component.html',
  styleUrls: [
    './../../../../base/shared/components/element-search-input/element-search-input.component.scss',
  ],
  providers: [
    { provide: MatFormFieldControl, useExisting: ElementSearchInputComponent },
    ElementSearchDialogService,
  ],
})
export class ElementSearchInputComponent extends ElementSearchInputComponentBase {
  constructor(
    fb: FormBuilder,
    public fm: FocusMonitor,
    public elRef: ElementRef,
    public sharedService: SharedService,
    public elementSearchInputDialogService: ElementSearchDialogService,
    public translate: TranslateService
  ) {
    super(
      fb,
      fm,
      elRef,
      sharedService,
      elementSearchInputDialogService,
      translate
    );
  }
}
