import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "baseTruncate",
})
@Injectable()
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit, trail): string {
    let trimmedString = value;

    limit = limit ? limit : 10;
    trail = trail !== undefined ? trail : "...";

    if (!value) {
      return "";
    } else if (value.length > limit) {
      trimmedString = value.substr(0, limit);
      trimmedString = trimmedString.substr(
        0,
        Math.min(
          trimmedString.length,
          trimmedString.lastIndexOf(" ") < 0
            ? trimmedString.length
            : trimmedString.lastIndexOf(" ")
        )
      );
    }

    return trimmedString + trail;
  }
}
