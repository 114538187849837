import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TruncatePipe as TruncatePipeBase } from '@web/base/shared/pipes/truncate.pipe';

@Pipe({
  name: 'truncate',
})
@Injectable()
export class TruncatePipe extends TruncatePipeBase implements PipeTransform {
  transform(value: string, limit, trail = '...'): string {
    let trimmedString = value;

    limit = limit ?? 50;

    if (!value) return '';

    if (value.length > limit) {
      trimmedString = value.substring(0, limit);
      trimmedString = trimmedString.substring(
        0,
        Math.min(
          trimmedString.length,
          trimmedString.lastIndexOf(" ") < 0
            ? trimmedString.length
            : trimmedString.lastIndexOf(" ")
        )
      );
    } else trail = '';

    return trimmedString + trail;
  }
}
