import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NewslettersSubscriptionService } from './newsletters-subscription.service';
import { IResponse } from '@web/project/shared/models/response.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from '@web/project/shared/components/base/base.component';

@Component({
  selector: 'web-newsletters-subscription',
  templateUrl: './newsletters-subscription.component.html',
  styleUrls: ['./newsletters-subscription.component.scss'],
})
export class NewslettersSubscriptionComponent
  extends BaseComponent
  implements OnInit {
  newslettersText: string;
  form: FormGroup;

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public newslettersSubscriptionService: NewslettersSubscriptionService
  ) {
    super(translate, snackbar, dialog);
  }

  ngOnInit() {
    this.getTranslations(['core']);

    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      conditions: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  openDataProtection() {
    this.openSimpleDialog(
      this.translations['core']['dataProtection'],
      this.translations['core']['dataProtectionContent']
    );
  }

  submit() {
    const val = Object.assign({}, this.form.value);
    delete val['conditions'];

    this.newslettersSubscriptionService
      .create(val)
      .subscribe((res: IResponse) => {
        this.openSnackbar(this.translations['general'].messageOkSend);
        this.form.controls['name'].setValue(null);
        this.form.controls['email'].setValue(null);
      });
  }
}
