import { IResponse } from '@web/project/shared/models/response.model';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Component, OnInit } from '@angular/core';
import { TemplateComponent as TemplateComponentBase } from '@web/base/core/template/template.component';
import { getCookie, order } from '@web/base/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '@web/project/shared/shared.service';
import { CoreService } from '@web/base/core/core.service';
import { MenuService } from '@web/base/core/menu/menu.service';
import { SwUpdate } from '@angular/service-worker';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Meta } from '@angular/platform-browser';
import { CookiesService } from '../cookies/cookies.service';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'web-project-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent extends TemplateComponentBase implements OnInit {
  public bannersCertamenes = [];
  public bannersConvenios = [];

  public hideBreadcrumb;

  public searchActive = false;
  public formSearch: FormGroup;
  public MIN_LENGTH_TO_SEARCH = ConstantsProject.MIN_LENGTH_TO_SEARCH;
  public isMobile: boolean;

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public coreService: CoreService,
    public menuService: MenuService,
    public swUpdate: SwUpdate,
    public route: ActivatedRoute,
    public router: Router,
    public breakpointObserver: BreakpointObserver,
    public meta: Meta,
    public cookiesService: CookiesService
  ) {
    super(
      translate,
      dialog,
      sharedService,
      coreService,
      menuService,
      swUpdate,
      route,
      router,
      breakpointObserver
    );
  }

  ngOnInit() {
    // super.ngOnInit();

    this.createForm();

    // Cookies ------------------------------
    this.cookiesService.showCookiesConsent.subscribe((v) => {
      this.showCookiesInfo = v;
    });
    if (getCookie(ConstantsProject.COOKIES_CONSENT) !== null) {
      this.cookiesService.showCookiesConsent.next(false);
      this.showCookiesInfo = false;
    } else {
      this.showCookiesInfo = true;
    }
    // --------------------------------------

    this.subscriptions.push(
      this.coreService.templateLoaded$.subscribe((v: IResponse) => {
        if (v) {
          this.bannersCertamenes = v.result.banners.filter(b => b.bannerPositionID === ConstantsProject.BANNER_CERTAMENES).sort((a, b) => order(a, b));
          this.bannersConvenios = v.result.banners.filter(b => b.bannerPositionID === ConstantsProject.BANNER_CONVENIOS).sort((a, b) => order(a, b));
        }
      })
    );

    this.subscriptions.push(
      this.coreService.isMobile$.subscribe((val) => (this.isMobile = val))
    );

    this.subscriptions.push(
      this.sharedService.showSidenav$.subscribe((v) => {
        setTimeout(() => (this.showSidenav = v));
      })
    );

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((evt) => {
        console.log('service worker updated');
      });

      this.swUpdate
        .checkForUpdate()
        .then(() => {
          // noop
        })
        .catch((err) => {
          console.error('error when checking for update', err);
        });
    }

    this.getTranslations(['core']);

    this.menuService.createMenu();

    this.subscriptions.push(
      this.coreService.templateLoaded$.subscribe((v: IResponse) => {
        if (v) {
          this.events.next(v.result.calendar);
          // this.calendarObj.today();

          // this.bannersFooter = v.result.banners
          //   .filter((b) => b.bannerPositionID === this.BANNER_BOTTOM)
          //   .sort((a, b) => order(a, b));

          // this.bannersSidenav = v.result.banners
          //   .filter((b) => b.bannerPositionID === this.BANNER_SIDE)
          //   .sort((a, b) => order(a, b));

          if (v.result.user && !v.result.user.conditionsAccepted) {
            if (
              v.result.user.elementTypeID ===
              ConstantsProject.ELEMENT_TYPE_COLLEGIATE
            ) {
              this.router.navigate(['mi-zona-privada', 'primer-acceso']);
            } else if (
              v.result.user.elementTypeID ===
              ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE
            ) {
              this.router.navigate([
                'mi-zona-privada-precolegial',
                'primer-acceso',
              ]);
            }
          }

          // ------------
          // Ventanas emergentes
          // ------------
          this.cookiesService.setCookiesConsent.subscribe((c) => {
            if (c) {
              this.route.queryParams.subscribe((params: Params) => {
                const popupId = params['showPopupId'];
                if (popupId) {
                  this.sharedService
                    .loadPopup(popupId)
                    .subscribe((res: IResponse) => {
                      this.dialog.open(PopupDialogComponent, {
                        panelClass: ['dialog', 'popup-dialog'],
                        width: '95%',
                        maxWidth: '750px',
                        data: { popup: res.result },
                      });
                    });
                } else {
                  v.result.popups.map((p) => {
                    if (
                      p &&
                      !getCookie(
                        ConstantsProject.COOKIES_PREFIX + 'popup_' + p.popupID
                      )
                    ) {
                      this.dialog.open(PopupDialogComponent, {
                        panelClass: ['dialog', 'popup-dialog'],
                        width: '95%',
                        maxWidth: '750px',
                        data: { popup: p },
                      });
                    }
                  });
                }
              });
            }
          });
        }
      })
    );

    this.subscriptions.push(
      this.sharedService.getSearchActive().subscribe((active) => {
        if (active === true) {
          this.searchActive = true;
          // document.querySelector('body').style.overflow = 'hidden';
          document.querySelector('#search-overlay').classList.remove('fade-out');
          document.querySelector('#search-overlay').classList.add('fade-in');
        }
        if (active === false) {
          this.searchActive = false;
          // document.querySelector('body').style.overflow = 'auto';
          document.querySelector('#search-overlay').classList.remove('fade-in');
          document.querySelector('#search-overlay').classList.add('fade-out');
        }
      })
    );

    this.coreService.loadTemplate();

    // Cerrar el sidenav si pasamos de 'resolución movil' a PC
    this.sidenavStatusSubscription = this.coreService
      .getSidenavStatus()
      .subscribe((status) => {
        if (status === false) {
          this.sidenav.close();
        } else {
          this.sidenav.open();
        }
      });

    // aviso Cookies
    this.showContent = true;
  }

  closeSearch() {
    this.sharedService.setSearchActive(false);
  }

  createForm() {
    this.formSearch = new FormGroup({
      text: new FormControl('', [
        Validators.required,
        Validators.minLength(this.MIN_LENGTH_TO_SEARCH),
      ]),
    });
  }

  searchAdvanced() {
    if (this.formSearch.controls['text'].value) {
      this.router.navigateByUrl(
        '/buscador/' + this.formSearch.controls['text'].value
      );
      this.formSearch.controls['text'].reset();
      this.closeSearch();
    }
  }

  onActivate(event) {
    this.hideBreadcrumb = (event.section === 'home');
    window.scroll(0, 0);
  }
}
