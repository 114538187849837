import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableComponent as TableComponentBase } from '@web/base/shared/components/table/table.component';

@Component({
  selector: 'web-table',
  templateUrl:
    './../../../../base/shared/components/table/table.component.html',
  styleUrls: [
    './../../../../base/shared/components/table/table.component.scss',
  ],
})
export class TableComponent extends TableComponentBase {
  constructor(
    public translate: TranslateService,
    public cp: CurrencyPipe,
    public dp: DatePipe
  ) {
    super(translate, cp, dp);
  }
}
