import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { AttachmentsComponent as AttachmentsComponentBase } from '@web/base/shared/components/attachments/attachments.component';
import { ConstantsProject } from '../../constants.class';

@Component({
  selector: 'web-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent extends AttachmentsComponentBase implements OnInit {

  constructor(public translate: TranslateService) {
    super(translate);
  }

  ngOnInit() {
    //super.ngOnInit();

    this.subscriptions.push(this.attachments.subscribe(atts => {
      this.files = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_FILE);
      this.images = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_IMAGE);
      this.videos = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_VIDEO);

      // Ordenamos por el campo "orden"
      this.files = this.files.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
      this.images = this.images.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
      this.videos = this.videos.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
    }));
  }
}
