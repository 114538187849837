import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";

import { URL } from "../../../environments/environment";
import { Template } from "./template/template.model";

@Injectable()
export class CoreService {
  // Banners -----------
  // public bannersHeader = new BehaviorSubject<any>(null);
  // public bannersFooter = new BehaviorSubject<any>(null);
  // public bannersSidenav = new BehaviorSubject<any>(null);
  // bannersHeader$ = this.bannersHeader.asObservable();
  // bannersFooter$ = this.bannersFooter.asObservable();
  // bannersSidenav$ = this.bannersSidenav.asObservable();
  //-------------

  sidenavStatus$ = new Subject<boolean>();
  isMobile$ = new BehaviorSubject<boolean>(false);

  public template = new BehaviorSubject<Template>(null);
  templateLoaded$ = this.template.asObservable();

  constructor(public http: HttpClient) { }

  loadTemplate() {
    const url = `${URL}/template`;

    return this.http.get(url).subscribe((data: Template) => {
      return this.template.next(data);
    });
  }

  loadCover() {
    const url = `${URL}/cover`;
    return this.http.get(url);
  }

  loadEventsByMonthAndYear(month: number, year: number) {
    const url = `${URL}/calendar/${month}/${year}`;
    return this.http.get(url);
  }

  loadSearch(filter: string) {
    const url = `${URL}/search/find`;
    return this.http.post(url, { filter });
  }

  getSidenavStatus() {
    return this.sidenavStatus$;
  }

  setSidenavStatus(status) {
    this.sidenavStatus$.next(status);
  }

  isMobile() {
    return this.isMobile;
  }

  setIsMobile(val) {
    this.isMobile$.next(val);
  }

  // setBannersHeader(banners) {

  //   console.log('banners header', banners);
  //   this.bannersHeader.next(banners);
  // }

  // setBannersFooter(banners) {

  //   console.log('banners footer', banners);
  //   this.bannersFooter.next(banners);
  // }

  // setBannersSidenav(banners) {

  //   console.log('banners side', banners);
  //   this.bannersSidenav.next(banners);
  // }
}
