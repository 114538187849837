import { Injectable } from '@angular/core';
import { URL } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService as AuthServiceBase } from '@web/base/auth/auth.service';
import { CoreService } from '@web/base/core/core.service';

@Injectable()
export class AuthService extends AuthServiceBase {
  errorLogin: string;
  public user: any;

  public loggedIn = new BehaviorSubject<boolean>(true);
  public loggedIn$ = this.loggedIn.asObservable();

  constructor(
    public coreService: CoreService,
    public http: HttpClient,
    public router: Router
  ) {
    super(coreService, http, router);
  }

  rememberPass(data: { type: number; number: string }) {
    let prefix;
    switch (data.type) {
      case ConstantsProject.ELEMENT_TYPE_COLLEGIATE:
        prefix = 'collegiates';
        break;
      case ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE:
        prefix = 'precollegiates';
        break;
      case ConstantsProject.ELEMENT_TYPE_RETIRED:
        prefix = 'retired';
        break;
      case ConstantsProject.ELEMENT_TYPE_VOLUNTARY:
        prefix = 'voluntary';
        break;
      default:
        break;
    }

    const url = `${URL}/${prefix}/remember-password`;

    return this.http.post(url, { number: data.number });
  }
}
