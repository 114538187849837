import { CoreService } from './../../../core.service';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';

export class ItemEmitterModel {
  index: number;
  menu: any;
}

@Component({
  selector: 'menu-mobile-item',
  templateUrl: './menu-mobile-item.component.html',
  styleUrls: ['./menu-mobile-item.component.scss'],
})
export class MenuMobileItemComponent extends BaseComponent implements OnInit {
  @Input() items;
  @Input() level;
  @Output() clickItem: EventEmitter<ItemEmitterModel> = new EventEmitter();
  @Output() clickFinalItem: EventEmitter<any> = new EventEmitter();
  @ViewChild('submenu', { static: true }) submenu: ElementRef;

  constructor(
    public translate: TranslateService,
    public coreService: CoreService,
    public router: Router
  ) {
    super(translate);
  }

  ngOnInit() {
    this.getTranslations(['menu']);

    this.level++;
  }

  onClickItem(index) {
    this.clickItem.emit({ index: index, menu: this.submenu.nativeElement });
  }

  propagateAction(event) {
    this.clickItem.emit({ index: event.index, menu: event.menu });
  }

  onClickFinalItem() {
    this.clickFinalItem.emit(true);
  }
}
