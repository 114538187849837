import { DomSanitizer } from "@angular/platform-browser";
import { PipeTransform, Pipe, Injectable } from "@angular/core";

@Pipe({ name: "baseSafeHtml" })
@Injectable()
export class SafeHtmlPipe implements PipeTransform {
  constructor(public sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
