import { Component, OnInit, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "web-base-simple-snackbar",
  templateUrl: "./simple-snackbar.component.html",
  styleUrls: ["./simple-snackbar.component.scss"],
})
export class SimpleSnackbarComponent implements OnInit {
  static TYPE_INFO = 1;
  static TYPE_OK = 2;
  static TYPE_ERROR = 3;

  public SNACKBAR_TYPE: any = {
    INFO: SimpleSnackbarComponent.TYPE_INFO,
    OK: SimpleSnackbarComponent.TYPE_OK,
    ERROR: SimpleSnackbarComponent.TYPE_ERROR,
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbarRef: MatSnackBarRef<SimpleSnackbarComponent>
  ) {
    if (!data.type) {
      data.type = SimpleSnackbarComponent.TYPE_INFO;
    }
  }

  ngOnInit() { }
}
