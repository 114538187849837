import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  OnInit,
  forwardRef,
  Input,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
} from '@angular/forms';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-base-tinymce-input',
  templateUrl: './tinymce-input.component.html',
  styleUrls: ['./tinymce-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TinymceInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TinymceInputComponent),
      multi: true,
    },
  ],
})
export class TinymceInputComponent implements OnInit, ControlValueAccessor {
  form: FormGroup;

  @Input() elementID: string;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() options = {};
  @Input() classList: string;
  @Input() height: number;

  @Output()
  finished: EventEmitter<string> = new EventEmitter();

  TINYMCE_API_KEY = ConstantsProject.TINYMCE_API_KEY;

  editor;
  config;

  images = '';
  tables = '';
  videos = '';
  preview = '';
  showMenu = {};
  menu = {};

  value;

  onChange = (_: any) => { };
  onTouch = () => { };

  constructor(public elRef: ElementRef) { }

  ngOnInit() {
    this.images = this.options['images'] ? 'image' : '';
    this.showMenu = this.options['showMenu'] ? this.options['showMenu'] : false;
    this.preview = this.options['preview'] ? 'preview' : '';
    this.height = this.height || 400;

    if (this.options['tables']) {
      this.tables = 'table';
      this.menu = {
        edit: {
          title: 'Editar',
          items: 'undo redo | cut copy paste pastetext | selectall',
        },
        format: {
          title: 'Formato',
          items:
            'bold italic underline strikethrough superscript subscript | formats | removeformat',
        },
        table: {
          title: 'Tabla',
          items: 'inserttable tableprops deletetable | cell row column',
        },
        insert: { title: 'Insertar', items: this.videos },
      };
    } else {
      this.menu = {
        edit: {
          title: 'Editar',
          items: 'undo redo | cut copy paste pastetext | selectall',
        },
        format: {
          title: 'Formato',
          items:
            'bold italic underline strikethrough superscript subscript | formats | removeformat',
        },
        insert: { title: 'Insertar', items: this.videos },
      };
    }

    this.form = new FormGroup({
      text: new FormControl('<div class="tinymce-content"></div>'),
    });

    if (this.required && this.form.enabled) {
      this.form.controls['text'].setValidators([this.validate.bind(this)]);
    }

    this.form.valueChanges.subscribe((val) => {
      if (!val.text || !val.text.length) {
        val.text = '<div class="tinymce-content"></div>';
      }

      this.onChange(val.text);

      this.finished.emit(val.text);
    });

    this.config = {
      base_url: '/tinymce', // Root for resources
      suffix: '.min', // Suffix to use when loading resources
      plugins: [
        'advlist autolink lists link image preview charmap print hr anchor',
        'searchreplace visualblocks visualchars code fullscreen',
        'insertdatetime media nonbreaking save ' +
        this.tables +
        ' directionality',
        'template paste textpattern',
      ],
      // tslint:disable-next-line:max-line-length
      toolbar1:
        'insertfile undo redo | bold italic | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
        this.images +
        ' | forecolor backcolor | fontsizeselect | ' +
        this.preview +
        ' | code',
      menubar: this.showMenu,
      menu: this.menu,

      fontsize_formats:
        '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 30pt 36pt',
      font_formats: 'Roboto',

      forced_root_block: false,
      force_br_newlines: false,

      entity_encoding: 'raw', // para que guarde en bbdd con la codificación definida en vez de poner acutes
      insert_toolbar: 'quickimage',
      toolbar_items_size: 'small',
      height: +this.height,
      branding: false,

      // ===========================================
      // SET RELATIVE_URLS to FALSE (This is required for images to display properly)
      // ===========================================

      relative_urls: false,
      remove_script_host: false,
      convert_urls: false,

      style_formats_merge: true,
      style_formats: [
        {
          title: 'Alinear Imagen Izquierda',
          selector: 'img',
          styles: {
            float: 'left',
            margin: '0 15px 0 0',
          },
        },
        {
          title: 'Alinear Imagen Derecha',
          selector: 'img',
          styles: {
            float: 'right',
            margin: '0 0 15px 15px',
          },
        },
      ],
      tools: 'inserttable',

      media_live_embeds: true,
      // content_css: '//fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:300,400,700',
      plugin_preview_width: 1200,

      setup: (editor) => {
        this.editor = editor;

        editor.on('keyup change', () => {
          let content = editor.getContent();

          if (content.substr(0, 4) !== '<div') {
            content = '<div class="tinymce-content">' + content + '</div>';
            editor.setContent(content);
          }

          content = content.replace(/\n/gi, '');

          this.onChange(content); // Esto modifica el valor del form control
        });

        // En el caso de que el valor llegue antes que el editor esté preparado.
        if (this.value) {
          // Esta absurdez evita el error 'ExpressionChangedAfterItHasBeenCheckedError'.
          setTimeout(() => {
            this.editor.setContent(this.value);
            this.form.setValue({ text: this.value });
          }, 0);
        }
      },
    };
  }

  // Estas funciones son necesarias para poder utilizar formControlName en el template

  validate(c: FormControl) {
    if (c.value) {
      const result = c.value.replace(/<\/?[^>]+>/gi, '').replace(/\s/gi, '');
      return result.length > 0 ? null : { required: true };
    }

    return null;
  }

  writeValue(value: any): void {
    if (this.editor && this.editor.id === this.elementID) {
      if (value) {
        this.editor.setContent(value);
        this.form.setValue({ text: value });
        return;
      }

      this.editor.setContent('');
    } else if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      // this.form.controls['text'].clearValidators();
      // this.form.controls['text'].updateValueAndValidity();
      this.form.disable();
    } else {
      // this.form.controls['text'].setValidators([this.validate.bind(this)]);
      // this.form.controls['text'].updateValueAndValidity();
      this.form.enable();
    }
  }
}
