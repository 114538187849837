import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ITableEstructure } from '@web/project/shared/models/table-structure.model';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-base-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild(MatMenuTrigger, { static: false }) optionsMenu: MatMenuTrigger;

  @Input() items: Subject<any>;
  @Input() itemsSelected: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  @Input() itemsChecked: { [name: string]: BehaviorSubject<Array<any>> } = {};
  @Input() fieldSelected = '';
  // @Input('clear') clear: Subject<any>;
  @Input() defaultLoading = true;

  // @Input('canPrint') canPrint = false;
  // @Input('canDownloadCSV') canDownloadCSV = false;
  // @Input('canUpdateXML') canUpdateXML = false;
  // @Output('printClick') printClick = new EventEmitter<any>();
  // @Output('downloadCSVClick') downloadCSVClick = new EventEmitter<any>();
  // @Output('updateXMLClick') updateXMLClick = new EventEmitter<any>();

  @Input() canEdit = true;
  @Input() canHover = false;
  @Input() canDelete = true;
  @Input() canBatchActions = false;
  @Input() additionalButtons: Array<{
    name: string;
    icon: string;
    title?: string;
  }> = [];
  // @Input('additionalOptions') additionalOptions: Array<{ name: string, icon: string, title?: string }> = [];
  // tslint:disable-next-line: max-line-length
  @Input() additionalOptions: Array<{
    name: string;
    icon: string;
    title?: string;
    badge?: string;
  }> = [];
  @Input() allOptionsVisible = false;
  @Input() filter = false;
  @Input() paginate = true;
  @Input() footer = false;
  @Input() page = 0;
  @Input() quantity = 50;
  @Input() total: number;
  @Input() tableClassList: string;
  @Input() containerClassList: string;
  @Input() structure: Array<ITableEstructure>;
  @Input() classElement: (item) => string;

  @Output() editClick = new EventEmitter<any>();
  @Output() deleteClick = new EventEmitter<any>();
  // @Output('nextPage') nextPage = new EventEmitter<number>();
  @Output() loadPage = new EventEmitter<number>();
  // @Output('order') order = new EventEmitter<string>();
  @Output() itemClick = new EventEmitter<any>();
  @Output() optionClick = new EventEmitter<{
    functionName: string;
    event: { item: any; index: number };
  }>();
  @Output() buttonClick = new EventEmitter<{ functionName: string }>();

  data: Array<any> = [];
  public loaded = false;

  public form: FormGroup;

  public amountSelected = 0;
  public amountsSelected = [];
  public skipChangeCheck = false;

  previousThis;
  withData = false;

  // public itemsSubscription: Subscription;
  // public clearSubscription: Subscription;

  constructor(
    public translate: TranslateService,
    public cp: CurrencyPipe,
    public dp: DatePipe
  ) {
    super(translate);
  }

  // Estamos pendientes de los cambios, por si se da el caso de que los datos lleguen antes que la estructura.
  ngOnChanges() {
    if (this.previousThis !== this) {
      this.processData(this.data);
    }
  }

  ngOnInit() {
    this.form = new FormGroup({
      itemsSelected: new FormArray([]),
    });

    if (this.defaultLoading === false) {
      this.loaded = true;
    }

    this.subscriptions.push(
      this.items.subscribe((data) => {
        this.loaded = true;
        this.uncheckAll();
        (<FormArray>this.form.controls['itemsSelected']).controls.splice(
          0,
          (<FormArray>this.form.controls['itemsSelected']).length
        );

        this.structure
          .filter((f) => f.check)
          .forEach((f) => {
            this.uncheckAll(f.value);
            if (this.form.controls[f.value]) {
              (<FormArray>this.form.controls[f.value]).controls.splice(
                0,
                (<FormArray>this.form.controls[f.value]).length
              );
            }
          });

        this.withData = true;
        this.processData(data);
      })
    );
  }

  /**
   * Para poder asignar pipes a los campos de la estructura:
   * - Buscamos la pipes y le asignamos su campo correspondiente.
   * - Mapeamos los items recibidos y les aplicamos las pipes correspondientes.
   */
  processData(data: Array<any>) {
    if (this.withData && this.structure && this.structure.length) {
      // Por cada campo de la estructura de tipo check, creamos un ArrayForm para gestionarlo.
      this.structure
        .filter((f) => f.check)
        .forEach((f) => {
          if (!this.form.controls[f.value]) {
            this.form.addControl(f.value, new FormArray([]));

            this.amountsSelected[f.value] = this.data.filter(
              (i) => i[f.value]
            ).length;
          }
        });

      this.previousThis = this;

      this.applyPipes(data);

      this.data.forEach((d) => {
        (<FormArray>this.form.controls['itemsSelected']).push(
          new FormControl(d[this.fieldSelected])
        );

        // Por cada item de tipo check, añadimos un FormControl a su correspondiente array.
        this.structure
          .filter((r) => r.check)
          .forEach((f) => {
            if (this.form.controls[f.value]) {
              (<FormArray>this.form.controls[f.value]).push(
                new FormControl(d[f.value])
              );

              // this.itemsChecked.push({ value: f.value, subject: new BehaviorSubject([]) });

              this.handleItemsChecked(f.value); // Manejador para el resto de checks de cada item.
            }
          });
      });

      this.structure
        .filter((r) => r.check)
        .forEach((f) => {
          (<FormArray>this.form.controls[f.value]).controls.forEach((c, i) => {
            c.valueChanges.subscribe((v) => {
              if (!this.skipChangeCheck) {
                if (v) {
                  this.amountsSelected[f.value]++;
                } else {
                  this.amountsSelected[f.value]--;
                }

                this.data[i][f.value] = !this.data[i][f.value];
                this.applyPipes(this.data);

                this.handleItemsChecked(f.value);
              }
            });
          });
        });

      this.handleItemsSelected(); // Manejador para el check principal de cada item.

      // Contador de items que tienen el check principal marcado
      this.amountSelected = this.data.filter(
        (i) => i[this.fieldSelected]
      ).length;

      // Gestiona el cambio de valor del check principal de cada item.
      (<FormArray>this.form.controls['itemsSelected']).controls.forEach((c) => {
        c.valueChanges.subscribe((v) => {
          if (!this.skipChangeCheck) {
            if (v) {
              this.amountSelected++;
            } else {
              this.amountSelected--;
            }

            this.handleItemsSelected();
          }
        });
      });
    } else if (this.withData && data) {
      this.data = data;
    }
  }

  applyPipes(data) {
    const pipes = [];
    this.structure.forEach((s, i) => {
      if (s.pipe !== undefined) {
        pipes.push({ field: s.value, index: i, ...s.pipe });
      }
    });

    this.data = data.map(
      (item) => {
        pipes.forEach((pipe) => {
          item[pipe.index + '_pipe'] = this.customPipe(
            pipe,
            item[pipe.field],
            item,
            pipe.field
          );
        });

        if (this.classElement) {
          item['classTR'] = this.classElement(item);
        }

        return item;
      },
      (err) => (this.loaded = true)
    );
  }

  handleItemsSelected() {
    this.itemsSelected.next(
      this.data
        .filter((value, index) => {
          if (
            (<FormArray>this.form.controls['itemsSelected']).controls[index]
              .value
          ) {
            this.data[index].index = index;
            return true;
          }
          return false;
        })
        .map((item) => {
          return {
            index: item.index,
            item: item,
          };
        })
    );
  }

  handleItemsChecked(field: string) {
    const subject = this.itemsChecked[field];
    if (subject) {
      subject.next(
        this.data
          .filter((value, index) => {
            if (
              this.form.controls[field] &&
              (<FormArray>this.form.controls[field]).controls[index]
            ) {
              // && (<FormArray>this.form.controls[field]).controls[index].value) {
              this.data[index].index = index;
              return true;
            }
            return false;
          })
          .map((item) => {
            return {
              index: item.index,
              item: item,
            };
          })
      );
    }
  }

  customPipe(pipe, val, item?, field?): string {
    // Aplicar la Pipe que se indica en la estructura al campo que la lleve.
    if (pipe) {
      let pipeParameters = pipe.parameters ? pipe.parameters : [];

      switch (pipe.name) {
        case 'surtaxRepayment':
          pipeParameters = pipeParameters.length
            ? pipeParameters
            : ['EUR', 'symbol', '1.2-2'];
          val = item.applySurtax ? item.surtaxBank : 0;
          val = this.cp.transform(val, ...pipeParameters);
          break;

        case 'currency':
          const parameters = pipeParameters.length
            ? pipeParameters
            : ['EUR', 'symbol', '1.2-2'];
          val = this.cp.transform(val, ...parameters);
          break;

        case 'receiptStatus':
        case 'transferStatus':
        case 'paymentStatus':
        case 'paymentStatus':
        case 'registerStatus':
          val =
            '<div class="status-' +
            val +
            '">' +
            this.translations['general']['registerStatus'][val] +
            '</div>';
          break;

        case 'translations':
          val = val[0][pipeParameters[0]];
          break;

        case 'percent':
          val = this.cp.transform(
            val,
            pipeParameters[0],
            pipeParameters[1],
            pipeParameters[2]
          );
          break;

        case 'date':
          pipeParameters = pipeParameters || 'mediumDate';
          // const localTime = moment.utc(val).local();
          // val = (val === null || val === '-') ? '-' : this.dp.transform(localTime, ...pipeParameters);
          val =
            val === null || val === '-'
              ? '-'
              : this.dp.transform(val, ...pipeParameters);
          break;

        case 'check':
          pipeParameters = pipeParameters[0] || 'check_circle';
          val = val ? '<i class="material-icons">' + pipeParameters + '</i>' : '';
          break;

        case 'inactive':
          val = val ? '' : '<div class="inactive-marker"></div>';
          break;

        case 'gender':
          val =
            val === 'f'
              ? this.translations['general']['genders']['female']
              : this.translations['general']['genders']['male'];
          break;

        case 'url':
          val = '<a href="' + val + '" onclick="event.stopPropagation()" target="_blank">' + val + '</a>';
          break;

        case 'color':
          val = val ? '<div class="color" style="background-color:' + val + '; width: 14px; height: 14px;"></div>' : '';
          break;

        case 'image':
          const indexImage = pipeParameters ? pipeParameters[0] || 0 : 0;
          val = val[indexImage].url && val[indexImage].url !== 'error'
            ? '<img src="' + val[indexImage].url + '" alt="" style="width: 50px; height: 50px;">'
            : '';
          break;

        case 'file':
          const indexFile = pipeParameters ? pipeParameters[0] || 0 : 0;
          val = val[indexFile].url && val[indexFile].url !== 'error'
            ? '<a onclick="event.stopPropagation()" download href="' + val[indexFile].url + '" alt=""><i class="material-icons">save_alt</i></a>'
            : '';
          break;

        case 'chip':
          val = '<div class="chip">' + val + '</div>';
          break;
      }
    }
    return val;
  }

  callOption(functionName: string, item: any, index: number) {
    if (this.optionsMenu) {
      this.optionsMenu.closeMenu();
    }
    this.optionClick.emit({ functionName, event: { item, index } });
  }

  callButton(functionName: string) {
    if (this.optionsMenu) {
      this.optionsMenu.closeMenu();
    }

    this.buttonClick.emit({ functionName });
  }

  // print() {
  //   if (this.optionsMenu) { this.optionsMenu.closeMenu(); }
  //   this.printClick.emit();
  // }

  // downloadCSV() {
  //   if (this.optionsMenu) { this.optionsMenu.closeMenu(); }
  //   this.downloadCSVClick.emit();
  // }

  // updateXML() {
  //   if (this.optionsMenu) { this.optionsMenu.closeMenu(); }
  //   this.updateXMLClick.emit();
  // }

  del(item: any, index: number) {
    if (this.optionsMenu) {
      this.optionsMenu.closeMenu();
    }
    this.deleteClick.emit({ item, index });
  }

  edit(item: any, index: number) {
    if (this.optionsMenu) {
      this.optionsMenu.closeMenu();
    }
    this.editClick.emit({ item, index });
  }

  open(item: any, index: number) {
    if (this.optionsMenu) {
      this.optionsMenu.closeMenu();
    }
    this.itemClick.emit({ item, index });
  }

  getPage(index: number) {
    this.loadPage.emit(index - 1);
  }

  batchChange(event, field?: string) {
    if (event.checked) {
      this.checkAll(field);
    } else {
      this.uncheckAll(field);
    }
  }

  checkAll(field?: string) {
    this.skipChangeCheck = true;

    if (field) {
      (<FormArray>this.form.controls[field]).controls.forEach((c, i) => {
        c.setValue(true);
        this.data[i][field] = 1;
      });

      this.applyPipes(this.data);

      this.itemsChecked[field].next(
        this.data.map((item, index) => {
          return { index: index, item: item };
        })
      );

      this.amountsSelected[field] = this.data.length;
    } else {
      (<FormArray>this.form.controls['itemsSelected']).controls.forEach((c) => {
        c.setValue(true);
      });

      this.itemsSelected.next(
        this.data.map((item, index) => {
          return { index: index, item: item };
        })
      );

      this.amountSelected = this.data.length;
    }

    this.skipChangeCheck = false;
  }

  uncheckAll(field?: string) {
    this.skipChangeCheck = true;

    if (field) {
      (<FormArray>this.form.controls[field]).controls.forEach((c, i) => {
        c.setValue(false);
        this.data[i][field] = 0;
      });

      this.applyPipes(this.data);

      this.itemsChecked[field].next([]);

      this.amountsSelected[field] = 0;
    } else {
      (<FormArray>this.form.controls['itemsSelected']).controls.forEach((c) => {
        c.setValue(false);
      });

      this.itemsSelected.next([]);

      this.amountSelected = 0;
    }
    this.skipChangeCheck = false;
  }
}
