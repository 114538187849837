import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { IBreadCrumb } from "./models/breadcrumb.model";
import { HttpClient } from "@angular/common/http";
import { URL } from "../../../environments/environment";
import { preparePagination } from "./utils";

@Injectable()
export class SharedService {
  loading = false;

  // public reloadBreadcrumbs = new Subject<ActivatedRoute>();
  public reloadBreadcrumbs = new Subject<Array<IBreadCrumb>>();
  public reloadBreadcrumbs$ = this.reloadBreadcrumbs.asObservable();

  public showSidenav = new Subject<boolean>();
  public showSidenav$ = this.showSidenav.asObservable();

  pathBaseFormations = URL + "/formations";

  constructor(public http: HttpClient) { }

  loadPopup(id) {
    const url = `${URL}/popups/${id}`;
    return this.http.get(url);
  }

  loadOrganizationChart() {
    const url = `${URL}/organization-chart/find`;
    return this.http.post(url, {});
  }

  loadNationalities() {
    const url = `${URL}/nationalities/find`;
    return this.http.post(url, { filter: null });
  }

  loadUniversities() {
    const url = `${URL}/universities/find`;
    return this.http.post(url, { filter: null });
  }

  loadCollegiates(filter: any) {
    const url = `${URL}/collegiates/find`;

    const values = preparePagination(filter);

    values.values["filter"] = values.values["filter"] || "%";
    return this.http.post(url, values.values, values.pagination);
  }

  existsDocumentNumber(collegiateID: number, documentNumber: string) {
    const url = `${URL}/collegiates/${collegiateID}/exists`;
    return this.http.post(url, { documentNumber });
  }

  // Esta función se encarga de procesar la galería de adjuntos antes de subirlos.
  // El último parámetro indica si se deben procesar cada uno de los adjuntos.

  handleAttachments(attachments, formData) {
    formData.delete("attachments");

    const atts = [];
    // const attsDelete = [];

    attachments.forEach((element, index) => {
      element.url = null;

      // Si queremos eliminar el archivo

      if (element.delete) {
        // Si el archivo aún no existe, simplemente eliminamos la clave para que no lo cree.
        // if (handleFiles && formData.has('file_' + index)) {
        //   // console.log('tiene para borrar ' + index);

        //   formData.delete('file_' + index);
        // }

        // attsDelete.push(element);
        element.deleted = true;
        atts.push(element);
      } else {
        atts.push(element);

        // Si el archivo aún no existe, creamos la clave con el índice siguiente al que ya existe.

        // if (handleFiles && formData.has('file_' + index)) {
        //   // console.log('tiene para crear ' + index);
        //   formData.append('file_' + atts.length, formData.get('file_' + index));
        //   formData.delete('file_' + index);

        // }
      }
    });

    formData.append("attachments", JSON.stringify(atts));
    // formData.append('attachments', atts);
    // formData.append('attachmentsDelete', JSON.stringify(attsDelete));
  }

  // Esta función se encarga de procesar los adjuntos propios de un elemento (por ejemplo, imagen principal).
  handleFiles(keys: Array<string>, attachments: Array<any>, formData) {
    const atts = [];
    formData.delete("files");

    keys.forEach((k, i) => {
      if (attachments[i]) {
        attachments[i].url = null;
        if (attachments[i].delete) {
          if (formData.has("file_" + k)) {
            // console.log('tiene para borrar ' + k);

            formData.delete("file_" + k);
          }

          attachments[i].deleted = true;
        }
        atts.push(attachments[i]);
      }
    });

    formData.append("files", JSON.stringify(atts));
    // formData.append('files', atts);
  }
}
