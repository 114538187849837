import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  AnimationEvent,
} from '@angular/animations';
import { ImageGalleryService } from './image-gallery/service/image-gallery.service';
import { IImageGallery } from '@web/project/shared/models/image-gallery.model';
import { MatGridList } from '@angular/material/grid-list';

@Component({
  selector: 'web-base-images-module',
  templateUrl: './images-module.component.html',
  styleUrls: ['./images-module.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate('250ms ease-in')),
    ]),
  ],
})
export class ImagesModuleComponent implements OnInit, AfterViewInit {
  @ViewChild('imageGallery', { static: false }) imageGallery: ElementRef;
  @ViewChild('grid', { static: false }) grid: MatGridList;

  @Input() images: IImageGallery[];
  @Input() title: string;
  @Input() order: 'asc' | 'desc' = 'asc';
  @Input() showTitle: boolean;

  public galleryColumns: number;
  public innerWidth: number;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.recalculateGrid();
  }

  constructor(public galleryService: ImageGalleryService) { }

  ngOnInit() {
    this.images.map((element) => {
      element.loaded = false;
    });

    if (this.order === 'asc') {
      this.images.sort(this.compareOrderAsc);
    } else {
      this.images.sort(this.compareOrderDesc);
    }
  }

  ngAfterViewInit() {
    this.recalculateGrid();
  }

  openImage(img: IImageGallery, galleryIndex: number, imageIndex: number) {
    this.galleryService.open({
      image: img,
      gallery: this.images.length > 1 ? this.images : null,
      imageIndex: imageIndex,
    });
  }

  recalculateGrid() {
    this.innerWidth = +(<ElementRef>(
      this.imageGallery.nativeElement.clientWidth
    ));
    if (this.innerWidth < 300) {
      this.galleryColumns = 2;
    } else if (this.innerWidth >= 300 && this.innerWidth < 500) {
      this.galleryColumns = 4;
    } else {
      this.galleryColumns = 8;
    }

    this.grid.cols = this.galleryColumns;
  }

  onLoadImage(imageIndex, event) {
    this.images[imageIndex].loaded = true;
  }

  compareOrderAsc(a, b) {
    if (a.order < b.order) {
      return -1;
    } else if (a.order > b.order) {
      return 1;
    } else {
      return 0;
    }
  }

  compareOrderDesc(a, b) {
    if (a.order < b.order) {
      return 1;
    } else if (a.order > b.order) {
      return -1;
    } else {
      return 0;
    }
  }
}
