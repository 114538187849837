import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';

import * as AuthActions from './auth.actions';
import { UserLogin } from './../user-login.model';
import { UserAWS } from './../user-aws.model';
import { URL } from '../../../../environments/environment';

import { map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { CoreService } from '@web/base/core/core.service';
import { SigninService } from '@web/base/core/header/dialogs/signin/signin.service';

@Injectable()
export class AuthEffects {
  @Effect()
  signin = this.actions$.pipe(
    ofType(AuthActions.TRY_SIGNIN),
    map((action: AuthActions.TrySignin) => {
      return action.payload;
    }),
    switchMap((authData: UserLogin) => {
      let url;

      switch (authData.type) {
        case ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE:
          url = `${URL}/precollegiates/login`;
          break;
        case ConstantsProject.ELEMENT_TYPE_COLLEGIATE:
        default:
          url = `${URL}/collegiates/login`;
          break;
      }

      return this.http.post(url, {
        username: authData.username,
        password: authData.password,
      });
      // .subscribe(() => { }, (error: any) => {
      //   this.authService.errorLogin = error.error.msg;
      //   return [];
      // });
    }),
    mergeMap((data: { msg: string; result: UserAWS }) => {
      // localStorage.setItem('user', JSON.stringify(data.result.collegiate));

      localStorage.setItem(ConstantsProject.TOKEN_KEY, data.result.token);

      // get return url from route parameters or default reload page
      console.log('login', this.route, this.router);

      if (this.route.snapshot.queryParams['returnUrl']) {
        const subscription = this.authService.isLoggedIn().subscribe((res) => {
          if (res) {
            this.router.navigateByUrl(
              this.route.snapshot.queryParams['returnUrl']
            );
            subscription.unsubscribe();
          }
        });

        this.signinService.closeDialog();
        this.coreService.loadTemplate();
        // window.location.reload();
      } else {
        this.signinService.closeDialog();
        // window.location.reload();
        this.coreService.loadTemplate();
      }

      return [
        {
          type: AuthActions.SIGNIN,
        },
        {
          type: AuthActions.SET_TOKEN,
          payload: data.result.token,
        },
      ];
    }),
    catchError((error: any, caught) => {
      if (error instanceof HttpErrorResponse && error.error.result) {
        this.authService.errorLogin = error.error.result.message;
      }
      // return throwError(error);
      return caught;
    })
  );

  @Effect()
  signinByCodeAndHash = this.actions$.pipe(
    ofType(AuthActions.TRY_SIGNIN_BY_CODE_AND_HASH),
    map((action: AuthActions.TrySigninByCodeAndHash) => {
      return { code: action.code, hash: action.hash };
    }),
    switchMap((authData: { code: string; hash: string }) => {
      const url = `${URL}/collegiates/login-by-code-and-hash`;

      return this.http.post(url, authData);
      // .catch((error: any) => {
      //   this.authService.errorLogin = error.error.msg;
      //   return [];
      // });
    }),
    mergeMap((data: { msg: string; result: UserAWS }) => {
      // localStorage.setItem('user', JSON.stringify(data.result.collegiate));

      localStorage.setItem(ConstantsProject.TOKEN_KEY, data.result.token);

      // get return url from route parameters or default reload page

      if (this.route.snapshot.queryParams['returnUrl']) {
        this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
        this.signinService.closeDialog();
      } else {
        window.location.reload();
      }

      return [
        {
          type: AuthActions.SIGNIN,
        },
        {
          type: AuthActions.SET_TOKEN,
          payload: data.result.token,
        },
      ];
    })
  );

  constructor(
    public actions$: Actions,
    public router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    public signinService: SigninService,
    public authService: AuthService,
    public coreService: CoreService
  ) { }
}
