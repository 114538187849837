import { MenuItem } from "../core/menu/menu-item.model";

export class Constants {
  static TOKEN_KEY = "";

  static COOKIES_PREFIX = "";

  static TWITTER = "";

  static PROJECT_LOCATION_MAIN = { lat: 37.380154, lng: -5.983795 };

  static TINYMCE_API_KEY = "hl4sdlypcuswl3aix5kf24llti12t4nnzqf3mco0dztrnh59";

  // Caracteres minimos para que se active el Buscador
  static MIN_LENGTH_TO_SEARCH = 3;

  static OFFICE_ADDRESSES = [
    {
      id: 1,
      office: "Sede Colegio",
      info: {
        address: "Dirección CP Provincia",
        phone: "Tlf.: Teléfono / Mv: Móvil",
      },
    },
  ];
  static LANGUAGES = [
    {
      value: 1,
      text: "spanish",
      textReadable: "",
      icon: "base/es.jpg",
      default: true,
      isoCode: "es",
    },
    // {
    //   value: 2,
    //   text: 'english',
    //   textReadable: '',
    //   icon: 'base/en.jpg',
    //   default: false,
    //   isoCode: 'en'
    // }
  ];

  static ELEMENT_TYPE_COLLEGIATE = 2;
  static ELEMENT_TYPE_PRECOLLEGIATE = 3;

  static USERS_TYPES = [
    {
      name: "collegiates",
      value: Constants.ELEMENT_TYPE_COLLEGIATE,
      text: "",
    },
    {
      name: "precollegiates",
      value: Constants.ELEMENT_TYPE_PRECOLLEGIATE,
      text: "",
    },
  ];

  static DISPOSITION_LIST = 1;
  static DISPOSITION_GRID = 2;
  static DISPOSITION_JUMBOTRON = 3;
  static DISPOSITION_CAROUSEL = 4;

  static LIMIT_HEIGHT_TO_FIXED_MENU = 840;
  static HEADER_HEIGHT = 150;

  static ATTACHMENT_TYPE_FILE = 1;
  static ATTACHMENT_TYPE_IMAGE = 2;
  static ATTACHMENT_TYPE_VIDEO = 3;

  static MAX_SIZE_ATTACHMENTS = 10 * 1024 * 1024;

  static BANNER_TOP = 1;
  static BANNER_BOTTOM = 2;
  static BANNER_SIDE = 3;

  static ADVERTISEMENT_TYPE_OFFER = 1;
  static ADVERTISEMENT_TYPE_DEMAND = 2;

  static CONSTANT_TYPE_CONTACT_GENERAL = 1;
  static CONSTANT_TYPE_CONTACT_COMPLAINTS = 2;
  static CONSTANT_TYPE_CONTACT_CLAIMS = 3;
  static CONSTANT_TYPE_CONTACT_SUGGESTIONS = 4;

  static TYPES_REPORTS = {
    WELCOME: 1,
    REGISTRATION: 2,
    BE_REGISTRED: 3,
    BE_PAYMENT: 4,
    CERT_RETENTION: 5,
    LABELS: 6,
  };

  static GENDERS = [
    {
      value: "m",
      text: "man",
      textReadable: "",
    },
    {
      value: "f",
      text: "woman",
      textReadable: "",
    },
  ];

  static DOCUMENT_TYPES = [
    {
      value: "dni",
      text: "dni",
      textReadable: "",
    },
    {
      value: "nie",
      text: "nie",
      textReadable: "",
    },
    {
      value: "cif",
      text: "cif",
      textReadable: "",
    },
    {
      value: "pasaporte",
      text: "passport",
      textReadable: "",
    },
  ];

  static PROFESSIONAL_SITUATIONS = {
    active: [
      {
        value: 1,
        text: "fixed",
        textReadable: "",
      },
      {
        value: 2,
        text: "temporal",
        textReadable: "",
      },
      {
        value: 3,
        text: "freelancer",
        textReadable: "",
      },
    ],
    inactive: [
      {
        value: 4,
        text: "unemployed",
        textReadable: "",
      },
      {
        value: 5,
        text: "retired",
        textReadable: "",
      },
      {
        value: 6,
        text: "otherSituations",
        textReadable: "",
      },
    ],
  };

  static PROFESSIONAL_EXERCISES = [
    {
      value: 1,
      text: "freelancer",
      textReadable: "",
    },
    {
      value: 2,
      text: "private",
      textReadable: "",
    },
    {
      value: 3,
      text: "concertedPrivate",
      textReadable: "",
    },
    {
      value: 4,
      text: "ssp",
      textReadable: "",
    },
    {
      value: 5,
      text: "othersPublic",
      textReadable: "",
    },
  ];

  static PROFESSIONAL_WORKDAYS = [
    {
      value: 1,
      text: "full",
      textReadable: "",
    },
    {
      value: 2,
      text: "partial",
      textReadable: "",
    },
  ];

  static CENTER_COLLEGIATE_ROLES = [
    {
      value: "1",
      text: "administrator",
      textReadable: "",
    },
    {
      value: "2",
      text: "employee",
      textReadable: "",
    },
  ];

  static PROFESSIONAL_SOCIETY_COMPONENT_TYPES = [
    {
      value: 1,
      text: "collegiate",
      textReadable: "",
    },
    {
      value: 2,
      text: "external-collegiate",
      textReadable: "",
    },
    {
      value: 3,
      text: "no-collegiate",
      textReadable: "",
    },
  ];

  static VU_CHANGE_ENTITY_TYPE_TABLE = 1;
  static VU_CHANGE_ENTITY_TYPE_PROCEDURE = 2;

  static VU_CHANGE_ACTION_INSERT = 1;
  static VU_CHANGE_ACTION_UPDATE = 2;
  static VU_CHANGE_ACTION_DELETE = 3;
  static VU_CHANGE_ACTION_PROCEDURE = 4;

  // Estos tipos son los usados en el panel de admin.
  static CONTACT_TYPES = {
    GENERAL: 1,
    COMPLAINT: 2,
    CLAIM: 3,
    SUGGESTION: 4,
  };

  // Empleo

  static OFFER_EMPLOYMENT_STATUS_PENDING = 1;
  static OFFER_EMPLOYMENT_STATUS_APPROVED = 2;
  static OFFER_EMPLOYMENT_STATUS_REJECTED = 3;

  static OFFER_EMPLOYMENT_STATUSES = [
    {
      value: 1,
      text: "pending",
      textReadable: "",
    },
    {
      value: 2,
      text: "approved",
      textReadable: "",
    },
    {
      value: 3,
      text: "rejected",
      textReadable: "",
    },
  ];

  static OFFER_EMPLOYMENT_SALARY_TYPE_AGREEMENT = 1;
  static OFFER_EMPLOYMENT_SALARY_TYPE_PROVIDED = 2;

  static OFFER_EMPLOYMENT_SALARY_TYPES = [
    {
      value: 1,
      text: "agreement",
      textReadable: "",
    },
    {
      value: 2,
      text: "provided",
      textReadable: "",
    },
  ];

  static OFFER_EMPLOYMENT_WORKING_DAY_MORNING = 1;
  static OFFER_EMPLOYMENT_WORKING_DAY_AFTERNOON = 2;
  static OFFER_EMPLOYMENT_WORKING_DAY_SERVICES = 3;
  static OFFER_EMPLOYMENT_WORKING_DAY_COMPLETE = 4;

  static OFFER_EMPLOYMENT_WORKING_DAYS = [
    {
      value: 1,
      text: "morning",
      textReadable: "",
    },
    {
      value: 2,
      text: "afternoon",
      textReadable: "",
    },
    {
      value: 3,
      text: "services",
      textReadable: "",
    },
    {
      value: 4,
      text: "complete",
      textReadable: "",
    },
  ];

  static OFFER_EMPLOYMENT_DATA_TYPE_UNDEFINED = 1;
  static OFFER_EMPLOYMENT_DATA_TYPE_TEMPORARY = 2;

  static OFFER_EMPLOYMENT_DATA_TYPES = [
    {
      value: 1,
      text: "undefined",
      textReadable: "",
    },
    {
      value: 2,
      text: "temporary",
      textReadable: "",
    },
  ];

  static OFFER_EMPLOYMENT_CONTRACT_TYPE_EMPLOYEE = 1;
  static OFFER_EMPLOYMENT_CONTRACT_TYPE_AUTONOMOUS = 2;

  static OFFER_EMPLOYMENT_CONTRACT_TYPES = [
    {
      value: 1,
      text: "employee",
      textReadable: "",
    },
    {
      value: 2,
      text: "autonomous",
      textReadable: "",
    },
  ];

  static DEMAND_EMPLOYMENT_STATUS_PENDING = 1;
  static DEMAND_EMPLOYMENT_STATUS_APPROVED = 2;
  static DEMAND_EMPLOYMENT_STATUS_REJECTED = 3;
  static DEMAND_EMPLOYMENT_STATUS_REQUESTED = 4;

  static DEMAND_EMPLOYMENT_STATUSES = [
    {
      value: 1,
      text: "pending",
      textReadable: "",
    },
    {
      value: 2,
      text: "approved",
      textReadable: "",
    },
    {
      value: 3,
      text: "rejected",
      textReadable: "",
    },
    {
      value: 4,
      text: "requested",
      textReadable: "",
    },
  ];

  // Encuestas

  static POLL_QUESTION_TYPE_TEXT = 1;
  static POLL_QUESTION_TYPE_LONG_TEXT = 2;
  static POLL_QUESTION_TYPE_SELECT = 3;
  static POLL_QUESTION_TYPE_NUMERIC_RADIO = 4;
  static POLL_QUESTION_TYPE_MULTIPLE = 5;

  // Formación

  static FORMATION_MODALITIES = [
    {
      value: 1,
      text: "onSite",
      textReadable: "",
    },
    {
      value: 2,
      text: "semiOnSite",
      textReadable: "",
    },
    {
      value: 3,
      text: "remote",
      textReadable: "",
    },
    {
      value: 4,
      text: "online",
      textReadable: "",
    },
  ];

  static FORMATION_INSCRIPTION_TYPES = [
    {
      value: 1,
      text: "collegiates",
      textReadable: "",
    },
    {
      value: 2,
      text: "agreementSchools",
      textReadable: "",
    },
    {
      value: 3,
      text: "noAgreementSchools",
      textReadable: "",
    },
    {
      value: 4,
      text: "students",
      textReadable: "",
    },
    {
      value: 5,
      text: "noCollegiates",
      textReadable: "",
    },
  ];

  static FORMATION_INSCRIPTION_STATUS_INSCRIBIED = 1;
  static FORMATION_INSCRIPTION_STATUS_RESERVE = 2;
  static FORMATION_INSCRIPTION_STATUS_CANCELLED = 3;

  static FORMATION_INSCRIPTION_STATUSES = [
    {
      value: Constants.FORMATION_INSCRIPTION_STATUS_INSCRIBIED,
      text: "inscribed",
      textReadable: "",
    },
    {
      value: Constants.FORMATION_INSCRIPTION_STATUS_RESERVE,
      text: "reserve",
      textReadable: "",
    },
    {
      value: Constants.FORMATION_INSCRIPTION_STATUS_CANCELLED,
      text: "cancelled",
      textReadable: "",
    },
  ];

  static FORMATION_PAYMENT_METHOD_PENDING = 1;
  static FORMATION_PAYMENT_METHOD_FREE = 2;
  static FORMATION_PAYMENT_METHOD_SIMPLE_CARD = 3;
  static FORMATION_PAYMENT_METHOD_DOUBLE_CARD = 4;
  static FORMATION_PAYMENT_METHOD_SIMPLE_DOMICILIED = 5;
  static FORMATION_PAYMENT_METHOD_DOUBLE_DOMICILIED = 6;
  static FORMATION_PAYMENT_METHOD_QUADRUPLE_DOMICILIED = 7;

  static FORMATION_PAYMENT_METHODS = [
    {
      value: Constants.FORMATION_PAYMENT_METHOD_PENDING,
      text: "pending",
      textReadable: "",
    },
    {
      value: Constants.FORMATION_PAYMENT_METHOD_FREE,
      text: "free",
      textReadable: "",
    },
    {
      value: Constants.FORMATION_PAYMENT_METHOD_SIMPLE_CARD,
      text: "simpleCard",
      textReadable: "",
    },
    {
      value: Constants.FORMATION_PAYMENT_METHOD_DOUBLE_CARD,
      text: "doubleCard",
      textReadable: "",
    },
    {
      value: Constants.FORMATION_PAYMENT_METHOD_SIMPLE_DOMICILIED,
      text: "simpleDomicilied",
      textReadable: "",
    },
    {
      value: Constants.FORMATION_PAYMENT_METHOD_DOUBLE_DOMICILIED,
      text: "doubleDomicilied",
      textReadable: "",
    },
    {
      value: Constants.FORMATION_PAYMENT_METHOD_QUADRUPLE_DOMICILIED,
      text: "quadrupleDomicilied",
      textReadable: "",
    },
  ];

  static SCHOOL_TYPE_COMMUNITY = 1; // Colegios de tipo comunidad
  static SCHOOL_TYPE_PROVINCE = 2; // Colegios de tipo provincia

  static MOVEMENT_TYPE_CATEGORY_INITIAL = 1; // Motivo de alta inicial
  static MOVEMENT_TYPE_CATEGORY_REENTRY = 2; // Motivo de alta por reincorporación.
  static MOVEMENT_TYPE_CATEGORY_MOVE = 3; // Motivo de alta por traslado.
  static MOVEMENT_TYPE_CATEGORY_DISMISSAL_DEATH = 4; // Motivo de baja por defunción.
  static MOVEMENT_TYPE_CATEGORY_DISMISSAL_MOVE = 5; // Motivo de baja por traslado.
  static MOVEMENT_TYPE_CATEGORY_DISMISSAL_VOLUNTEER = 6; // Motivo de baja no ejercer.

  static MENU_SIDENAV: Array<MenuItem> = [];

  static MENU_HEADER: Array<MenuItem> = [
    { title: "home", url: "/", icon: "home" },
    {
      title: "college",
      icon: "domain",
      children: [
        { title: "location", url: "/colegio/localizacion" },
        { title: "board", url: "/colegio/junta-de-gobierno" },
        { title: "contact", url: "/contacto", separator: true },
      ],
    },
    {
      title: "collegiates",
      icon: "person_outline",
      children: [
        {
          title: "agreements",
          url: "/ventajas-para-colegiados",
          private: true,
        },
        { title: "circulars", url: "/contenidos/3/circulares", private: true },
        { title: "employment", url: "/bolsa-de-empleo", private: true },
      ],
    },
    {
      title: "services",
      icon: "favorite_border",
      children: [
        { title: "formation", url: "/cursos" },
        { title: "faqs", url: "/preguntas-frecuentes" },
        { title: "links", url: "/enlaces-de-interes" },
        { title: "polls", url: "/encuestas" },
      ],
    },
    {
      title: "publications",
      icon: "import_contacts",
      children: [
        { title: "news", url: "/contenidos/1/noticias" },
        { title: "advertisements", url: "/tablon-de-anuncios", private: true },
        { title: "videos", url: "/videos" },
        { title: "newsletters", url: "/boletin-digital" },
        { title: "imagesGalleries", url: "/galeria-imagenes" },
      ],
    },
    {
      title: "virtualOffice",
      // url: '/ventanilla-unica',
      icon: "tv",
      children: [
        {
          title: "professionalDirectory",
          url: "/ventanilla-unica/censo-de-colegiados",
        },
        {
          title: "SSPPDirectory",
          url: "/ventanilla-unica/censo-sociedades-profesionales",
        },
        { title: "centers", url: "/ventanilla-unica/centros" },
        { title: "whyCollegiate", url: "/ventanilla-unica/por-que-colegiarse" },
        { title: "registerCollegiate", url: "/ventanilla-unica/alta-colegial" },
        {
          title: "registerPrecollegiate",
          url: "/ventanilla-unica/alta-precolegial",
        },
        {
          title: "registerProfessionalSociety",
          url: "/ventanilla-unica/alta-sociedades-profesionales",
        },
        { title: "complaints", url: "/quejas-reclamaciones-sugerencias" },
        {
          title: "memories",
          url: "/contenidos/4/memorias",
          private: true,
          separator: true,
        },
        {
          title: "certificatesDownload",
          url: "/ventanilla-unica/descarga-de-certificados",
          private: true,
        },
        {
          title: "dismissals",
          url: "/ventanilla-unica/solicitud-baja-colegial",
          private: true,
        },
      ],
    },
  ];

  constructor() { }
}
