import { Component, ViewEncapsulation } from '@angular/core';
import { MenuHeaderComponent as MenuHeaderComponentBase } from '@web/base/core/menu/menu-header/menu-header.component';
import { MenuItem } from '../menu-item.model';

@Component({
  selector: 'web-project-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class MenuHeaderComponent extends MenuHeaderComponentBase {
  public menuHeader: MenuItem[];
}
