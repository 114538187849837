import { Component, OnInit, Inject, Optional } from '@angular/core';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
})
export class Error404Component extends BaseComponent {
  constructor(public translate: TranslateService) {
    super(translate);
  }
}
