import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { startWith, map } from 'rxjs/operators';
import { BaseComponent } from '@web/project/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-base-chips-autocomplete-input',
  templateUrl: './chips-autocomplete-input.component.html',
  styleUrls: ['./chips-autocomplete-input.component.scss'],
})
export class ChipsAutocompleteInputComponent
  extends BaseComponent
  implements OnInit {
  form: FormGroup;

  @Input('formArray') formArray: FormArray; // FormArray que utilizamos para gestionar las etiquetas seleccionadas.
  @Input('canCreate') canCreate = false; // Si al pulsar enter o coma se debe crear la etiqueta.
  @Input('keyField') keyField: string; // Nombre de la clave primaria de la opción.
  @Input('nameField') nameField: string; // Nombre del campo que contiene el valor a representar de la opción.

  @Input('visible') visible = true;
  @Input('selectable') selectable = true;
  @Input('removable') removable = true;
  @Input('addOnBlur') addOnBlur = true;

  @Input('separatorKeysCodes') separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() placeholder: string;
  @Input('required') required = false; // Si es required, en el template mostramos un mensaje de error si no hay nada en FormArray

  @Input('options$') options$: Observable<any[]>; // Observable con todas las opciones
  @Input('optionCreated$') optionCreated$: Observable<any>; // Observable con la opción creada

  // Evento emitido cuando se debe crear una nueva opción.
  // Cuando la opción se cree, hay que añadir la opción al FormArray.
  @Output()
  createOption: EventEmitter<string> = new EventEmitter();

  options = []; // Todas las opciones
  selectableOptions = []; // Todas las opciones seleccionables, esto es: todas las opciones menos las ya seleccionadas.
  selectedOptions = []; // Todas las opciones seleccionadas.
  filteredOptions: Observable<any[]>; // Opciones filtradas

  @ViewChild('optionInput' /*, { static: false }*/, { static: true })
  optionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(public translate: TranslateService) {
    super(translate);
  }

  ngOnInit() {
    this.form = new FormGroup({
      filter: new FormControl(null),
    });

    this.formArray.valueChanges.subscribe((v) => {
      this.handleOptions();
    });

    this.subscriptions.push(
      this.options$.subscribe((v) => {
        this.options = v;

        this.handleOptions();
      })
    );

    if (this.optionCreated$) {
      this.subscriptions.push(
        this.optionCreated$.subscribe((v) => {
          this.formArray.push(new FormControl(v));

          // Actualizamos las opciones disponibles.
          this.handleOptions();
        })
      );
    }

    this.filteredOptions = this.form.controls['filter'].valueChanges.pipe(
      startWith(null),
      map((option: any | null) =>
        option ? this._filterChip(option) : this.selectableOptions.slice()
      )
    );
  }

  // Actualiza las opciones seleccionable (todas menos las seleccionadas)
  handleOptions() {
    this.selectableOptions = this.options.filter(
      (o) => this.formArray.value.findIndex((v) => v === o[this.keyField]) < 0
    );
    this.selectedOptions = this.options.filter(
      (o) => this.formArray.value.findIndex((v) => v === o[this.keyField]) >= 0
    );

    this.optionInput.nativeElement.value = '';
    this.form.controls['filter'].setValue(null);
  }

  // Estas funciones son necesarias para poder utilizar formControlName en el template

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen && this.canCreate) {
      const input = event.input;
      const value = event.value;

      // Add our option
      if ((value || '').trim()) {
        this.createOption.emit(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.form.controls['filter'].setValue(null);
    }
  }

  remove(option: any): void {
    const index = this.formArray.value.findIndex(
      (c) => c === option[this.keyField]
    );

    if (index >= 0) {
      this.formArray.removeAt(index);
      this.handleOptions();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // Añadimos la opción seleccionada al array.
    this.formArray.push(new FormControl(event.option.value[this.keyField]));

    // Actualizamos las opciones disponibles.
    this.handleOptions();
  }

  public _filterChip(value: any): any[] {
    const filterValue =
      typeof value === 'string'
        ? value.toLowerCase()
        : value[this.nameField].toLowerCase();

    return this.selectableOptions.filter(
      (c) => c[this.nameField].toLowerCase().indexOf(filterValue) === 0
    );
  }
}
