import { Component, ViewEncapsulation } from '@angular/core';
import { MenuMobileComponent as MenuMobileComponentBase } from '@web/base/core/menu/menu-mobile/menu-mobile.component';
import { MenuItem } from '../menu-item.model';

@Component({
  selector: 'web-project-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: [
    './../../../../base/core/menu/menu-mobile/menu-mobile.component.scss',
    './menu-mobile.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MenuMobileComponent extends MenuMobileComponentBase {
  public menuMobile: MenuItem[];

  activateItem(item) {
    this.renderer.setAttribute(item, 'status', 'active');
    this.renderer.removeClass(item, 'inactive');
    this.renderer.addClass(item, 'active');
    const arrow = item.children[0].children[1];
    if (arrow) {
      // Para rotar la flecha
      this.renderer.addClass(arrow, 'active');
    }
  }

  deactivateItem(item) {
    this.renderer.setAttribute(item, 'status', 'inactive');
    this.renderer.removeClass(item, 'active');
    this.renderer.addClass(item, 'inactive');
    const arrow = item.children[0].children[1];
    if (arrow) {
      // Para rotar la flecha a su posición inicial
      this.renderer.removeClass(arrow, 'active');
    }
  }

  closeChildren(item) {
    const submenu = item.children[1].children[0];
    this.deactivateItem(submenu);
    const children = item.children[1].children[0].children;
    let i = 0;
    for (const li of children) {
      if (
        li.children.length > 1 &&
        li.attributes['status'].value === 'active'
      ) {
        this.deactivateItem(li);
        this.closeChildren(li);
      }
      i++;
    }
  }
}
